<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import generalForm from "./detail/general-form";
import workingHeightForm from "./form/working-height-form";
import excavationForm from "./form/excavation-form";
import confinedSpaceForm from "./form/confined-space-form";
import energizedForm from "./form/energized-form";
import hotForm from "./form/hot-form";
import moment from "moment";

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Add Work Permit Request",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        generalForm,
        workingHeightForm,
        excavationForm,
        confinedSpaceForm,
        energizedForm,
        hotForm,
    },
    data() {
        return {
            title: "Work Permit Request",
            searchDataTable: "",

            modal_submit: false,
            status_modal_submit: null,
            optionAdministrator: null,
            administratorSelected: null,
            pdf_path: null,
            detail_data_draft: null,
            closing_id: null,
            id_permit: null,

            work_permit_approved: [],
            work_permit_selected: [],
            work_permit_request_detail: null,

            optionsDeviation: [
                { value: true, label: "Yes" },
                { value: false, label: "No" },
            ],
            selectedDeviation: null,
            showPossibleHazard: false,
            showloading: false,

            file_evidence: [],
            closing_statement: null,

            // Possible Hazard
            dokumen_terlampir: [
                {
                    label: "Overtime Permit",
                    is_required: false,
                    slug: "overtime",
                    checklist: false,
                    nama_lain: null,
                    file: [
                        {
                            nama_file: null,
                            url: null,
                        },
                    ],
                },
            ],
            list_name_attendance: [],
            overtime_modal: false,
            overtime: {
                detail_lokasi: null,
                durasi_kerja_dari: null,
                durasi_kerja_sampai: null,
                performing_task: [
                    {
                        nama: null,
                        perusahaan: null,
                        is_disabled: false,
                    },
                ],
                description_task: null,
            },
            kemungkinan_bahaya: null,
            is_bahaya_rendah: false,
            is_bahaya_tinggi: false,
            bahaya_rendah: null,
            bahaya_tinggi: [
                {
                    label: "Working at Heights",
                    slug: "work_at_height",
                    checklist: false,
                    nama_bahaya: null,
                },
                {
                    label: "Hot Work",
                    slug: "hot_work",
                    checklist: false,
                    nama_bahaya: null,
                },
                {
                    label: "Excavation",
                    slug: "excavation",
                    checklist: false,
                    nama_bahaya: null,
                },
                {
                    label: "Confined Space",
                    slug: "confined_space",
                    checklist: false,
                    nama_bahaya: null,
                },
                {
                    label: "Energized Electrical Work",
                    slug: "electrical",
                    checklist: false,
                    nama_bahaya: null,
                },
                {
                    label: "Heavy lifting",
                    slug: "heavy_lifting",
                    checklist: false,
                    nama_bahaya: null,
                },
            ],
            tindakan_pencegahan: null,
            apd_data: ["Full Body Harnes", "Respirator", "Protection clothing", "Gloves", "Ear protection", "Lainnya"],
            apd_yang_diperlukan: [
                {
                    label: "Safety Helmet",
                    slug: "normal",
                    nama_lain: null,
                    checklist: false,
                },
                {
                    label: "Safety Shoes",
                    slug: "normal",
                    nama_lain: null,
                    checklist: false,
                },
                {
                    label: "Baju Kerja / Vest (rompi)",
                    slug: "normal",
                    nama_lain: null,
                    checklist: false,
                },
                {
                    label: "Sarung Tangan Kerja",
                    slug: "normal",
                    nama_lain: null,
                    checklist: false,
                },
                {
                    label: "Kacamata",
                    slug: "normal",
                    nama_lain: null,
                    checklist: false,
                },
                {
                    label: "Lainnya",
                    slug: "lainnya",
                    nama_lain: null,
                    checklist: false,
                },
            ],
            // form work at height
            work_at_height: {
                detail_lokasi: null,
                detail_pekerjaan: null,
                work_duration: null,
                work_duration_sampai: null,
                vendor_representative: null,
                tools: null,
                tanggal: null,
                apakah_harus_dihadiri_pengawas: "NA",
                checklist_persiapan: [
                    {
                        label: "Apakah anak tangga bebas dari oli, minyak, pelumas, atau segala jenis bahan yang licin?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah kaki tangga memakai alas antislip?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah scaffolding sudah dilengkapi lifeline?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah scaffolding sudah dilengkapi hoist arm attachment?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah scaffolding sudah dilengkapi akses tangga?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah scaffolding sudah dilengkapi toe board?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah scaffolding sudah dilengkapi guardrail/handrail?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah mudsill tersedia?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah alas plat dengan sekrup sudah terpasang dan dikuatkan ke mudsill?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah pengunci roda (locking castore) tersedia? (untuk jenis rolling scaffold)",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah full body harness tersedia?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah full body harness yang digunakan dilengkapi dengan titik-titik pengikat serta lanyard yang standar dan masih layak pakai?",
                        checklist: "NA",
                    },
                    {
                        label: "Lifeline kondisinya kuat, tahan gores, stabil terhadap bahan kimia, bisa diperluas, tidak cacat, dibalut pelapis ketika melalui ujung yang tajam, mengikat dan menyambung, dan dikencangkan pada tiang pancang yang kuat",
                        checklist: "NA",
                    },
                    {
                        label: "Terdapat penandaan dan barikade yang jelas selama pekerjaan berlangsung.",
                        checklist: "NA",
                    },
                    {
                        label: "Personel yang melakukan pekerjaan sudah memiliki kualifikasi K3 yang relevan.",
                        checklist: "NA",
                    },
                ],
                lampiran: [
                    {
                        label: "Sertifikat Tenaga Kerja Bangunan Tingkat Tinggi (TKBT)",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },

                    {
                        label: "Sertifikat Tenaga Kerja Pada Ketinggian (TKPK)",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                    {
                        label: "Sertifikat Ahli K3 Perancah (Scaffolding)",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                    {
                        label: "Sertifikat Teknisi K3 Perancah (Scaffolding)",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                    {
                        label: "Lainnya, sebutkan",
                        slug: "lainnya",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                ],
                apd_yang_diperlukan: [
                    {
                        label: "Full body harness",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Masker N95",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Masker R95",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Ear plug",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Ear muff",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },

                    {
                        label: "Lainnya",
                        slug: "lainnya",
                        nama_lain: null,
                        checklist: false,
                    },
                ],
            },
            // form hot work
            hot_work: {
                detail_lokasi: null,
                detail_hot_work: null,
                vendor_representative: null,
                apd_yang_diperlukan: [
                    {
                        label: "Kedok Las",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Sarung tangan Las",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Apron tangan Las",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Apron badan Las",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Faceshield",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Masker N95",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Masker R95",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Ear Plug",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Ear Muff",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Lainnya",
                        slug: "lainnya",
                        nama_lain: null,
                        checklist: false,
                    },
                ],
                work_duration: null,
                work_duration_sampai: null,
                spesifik_equipment: null,
                apakah_gas_awal_diperlukan: "NA",
                apakah_pengetesan_gas_diperlukan: "Tidak",
                apakah_instrumen_gas_terkalibrasi: "NA",
                apakah_gas_testing_dilakukan_oleh_orang_kompeten: "NA",
                apakah_pekerjaan_dihadiri_fire_watch: "No",
                initial_test: [
                    {
                        label: "Gas mudah terbakar- LEL 0%",
                        pembacaan: null,
                        hasil: null,
                    },
                    {
                        label: "Oxygen 20.5 - 23.5%",
                        pembacaan: null,
                        hasil: null,
                    },
                ],
                nama_fire_watch: null,
                checklist_persiapan: [
                    {
                        label: "Jalur di-disconnect dan/atau dikosongkan?",
                        checklist: "NA",
                    },
                    {
                        label: "Peralatan atau area cukup bersih dari tumpahan, kotoran, atau bahan mudah terbakar lainnya?",
                        checklist: "NA",
                    },
                    {
                        label: "Lubang got, pintu, jendela, sambungan selokan ditutup atau dilindungi?",
                        checklist: "NA",
                    },
                    {
                        label: "Peralatan dan operasi yang berdampingan aman dari sudut pandang dampak yang mungkin terjadi pada pekerjaan? (tidak ada pekerjaan pemutus saluran tambahan yang terletak dalam jarak 25 Meter dari titik penyalaan pekerjaan panas)",
                        checklist: "NA",
                    },
                    {
                        label: "Jika di dalam gedung / bangunan, apakah sprinkler atau sistem deteksi kebakaran tersedia?<br><b>Jika tidak, Hot Work dilarang!</b>",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah area terbebas dari debu?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah terdapat APAR di area pekerjaan yang sudah diinpeksi?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah Hidran dalam kondisi stand by?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah alat potong/las terata rapi?",
                        checklist: "NA",
                    },
                    {
                        label: "Fire Watch tersedia?<br>(fire watch harus tetap di lokasi kerja untuk 30 menit setelah pekerjaan berhenti)",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah ada hot surface/permukaan panas?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah blanket atau pelindung tahan api disediakan untuk melindungi material atau personel yang bersebelahan dari percikan api atau arc flash?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah ventilasi umum memadai?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah inert gas blanketing diperlukan?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah pasokan udara segar diperlukan? (Seperti di Confined Space)",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah semua area di bawah hot work bersih dari bahan mudah terbakar dan area sudah dibarikade?",
                        checklist: "NA",
                    },
                ],
                tanggal: null,
                lampiran: [
                    {
                        label: "Sertifikat Juru Las",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },

                    {
                        label: "Sertifikat K3 Deteksi Gas",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                    {
                        label: "Sertifikat Pemadam Kebakaran (Kelas A/B/C/D) ",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },

                    {
                        label: "Lainnya",
                        slug: "lainnya",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                ],
            },
            // Energized Electrical Work Permit
            electrical: {
                detail_lokasi: null,
                tools: null,
                tanggal: null,
                work_duration: null,
                work_duration_sampai: null,
                apakah_harus_dihadiri_pengawas: "NA",
                checklist_persiapan: [
                    {
                        label: "Apakah orang yang melakukan pekerjaan memenuhi syarat/terlatih?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah departemen yang relevan sudah diberi tahu mengenai isolasi?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah supply listrik telah dimatikan? ",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah zat mudah terbakar sudah disingkirkan?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah instrumen pendeteksi tegangan diperlukan?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah grounding diperlukan? ",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah circuit breakers diperlukan? ",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah sign bahaya/caution diperlukan?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah insulating mat diperlukan?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah sudah dilakukan LOTO terhadap isolator?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah containment percikan api diperlukan?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah sprinkler tersedia dan siap digunakan?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah heat resistant screens sudah terpasang?",
                        checklist: "NA",
                    },
                    {
                        label: "Lainnya, sebutkan…",
                        checklist: "NA",
                    },
                ],
                lampiran: [
                    {
                        label: "Lisensi Teknisi K3 Listrik",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },

                    {
                        label: "Lisensi Ahli K3 Listrik",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                    {
                        label: "Lainnya, sebutkan",
                        slug: "lainnya",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                ],
                vendor_representative: null,
                apd_yang_diperlukan: [
                    {
                        label: "Sarung tangan Electrical",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Safety shoes Electrical",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Masker N95",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Masker R95",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Ear plug",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Ear muff",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Lainnya",
                        slug: "lainnya",
                        nama_lain: null,
                        checklist: false,
                    },
                ],
            },
            confined_space: {
                detail_pekerjaan: null,
                detail_lokasi: null,
                tanggal: null,
                tools: null,
                work_duration: null,
                work_duration_sampai: null,
                apakah_tes_gas_awal_dipelukan: "Tidak",
                apakah_pengetesan_gas_diperlukan_saat_pekerjaan: "Tidak",
                apakah_instrumen_gas_terkalibrasi: "NA",
                apakah_gas_testing_dilakukan_oleh_orang_kompeten: "NA",
                initial_test: [
                    {
                        label: "Oxygen 19.5 - 23.5%",
                        pembacaan: null,
                        hasil: null,
                    },
                    {
                        label: "Combustible gas - LEL <10%",
                        pembacaan: null,
                        hasil: null,
                    },
                    {
                        label: "Concentration toxic material <50% TLV",
                        pembacaan: null,
                        hasil: null,
                    },
                    {
                        label: "Other, Specify",
                        pembacaan: null,
                        hasil: null,
                    },
                ],
                final_test: [
                    {
                        label: "Oxygen 19.5 - 23.5%",
                        pembacaan: null,
                        hasil: null,
                    },
                    {
                        label: "Combustible gas - LEL <10%",
                        pembacaan: null,
                        hasil: null,
                    },
                    {
                        label: "Concentration toxic material <50% TLV",
                        pembacaan: null,
                        hasil: null,
                    },
                    {
                        label: "Other, Specify",
                        pembacaan: null,
                        hasil: null,
                    },
                ],
                checklist_persiapan: [
                    {
                        label: "Semua koneksi mekanis telah dimatikan (mesin, kran uap, kran air, kran oli, dsb)",
                        checklist: "NA",
                    },
                    {
                        label: "Aliran listrik telah dimatikan",
                        checklist: "NA",
                    },
                    {
                        label: "Area kerja telah diberi pembatas",
                        checklist: "NA",
                    },
                    {
                        label: "Aliran udara yang cukup menggunakan blower, tersedia ketika pekerjaan berlangsung",
                        checklist: "NA",
                    },
                    {
                        label: "Saluran buangan gas atau asap tersedia",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah SCBA tersedia dan berfungsi dengan baik?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah safety line/tripod/harness dan peralatan pendukung lainnya tersedia?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah pekerja memiliki kualifikasi K3 yang relevan?",
                        checklist: "NA",
                    },
                ],

                lampiran: [
                    {
                        label: "Sertifikat Petugas K3 Utama Ruang Terbatas",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },

                    {
                        label: "Sertifikat Teknisi K3 Deteksi Gas",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                    {
                        label: "Sertifikat Petugas K3 Madya Ruang Terbatas",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                    {
                        label: "Lainnya, sebutkan",
                        slug: "lainnya",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                ],
                vendor_representative: null,
                apd_yang_diperlukan: [
                    {
                        label: "SCBA",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Full Body Harness",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Masker N95",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Masker R95",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Ear Plug",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Ear Muff",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Face shield",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Lainnya",
                        slug: "lainnya",
                        nama_lain: null,
                        checklist: false,
                    },
                ],
            },
            heavy_lifting: {
                detail_lokasi: null,
                peralatan_angkat: null,
                tanggal: null,
                work_duration: null,
                work_duration_sampai: null,
                metode_pengangkatan: null,
                apakah_harus_dihadiri_pengawas: "No",
                checklist_persiapan: [
                    {
                        label: "Apakah area sudah dibarikade dan akses sudah dibatasi?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah area sudah diberi safety/warning sign?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah alat angkut yang digunakan memiliki Surat Laik Operasi?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah alat angkut yang digunakan sudah diinspeksi sebelum digunakan dan dalam kondisi baik?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah personel yang mengoperasikan alat angkut sudah memiliki kompetensi K3 yang diperlukan?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah ukuran/berat beban yang diangkat tidak melebebihi kapasitas alat angkut yang digunakan?",
                        checklist: "NA",
                    },
                    {
                        label: "Area radius ayunan alat angkut bebas dari kabel dan/atau alat angkut lainnya",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah ada pengawas yang mengatur arus traffic alat angkut selama pengangkatan?",
                        checklist: "NA",
                    },
                ],
                lampiran: [
                    {
                        label: "Sertifikat K3 Operator Crane",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },

                    {
                        label: "Surat Laik Operasi Alat Angkat-Angkut",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                    {
                        label: "Sertifikat K3 Operator Forklift Diesel",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                    {
                        label: "Sertifikat/Laporan Hasil Uji Riksa Alat Angkat-Angkut",
                        slug: "normal",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                    {
                        label: "Lainnya, sebutkan",
                        slug: "lainnya",
                        checklist: false,
                        nama_lain: null,
                        file: [
                            {
                                nama_file: null,
                                url: null,
                            },
                        ],
                    },
                ],
                vendor_representative: null,
                apd_yang_diperlukan: [
                    {
                        label: "Masker N95",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Masker R95",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Ear Plug",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Ear Muff",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Full body harness",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Lainnya",
                        slug: "lainnya",
                        nama_lain: null,
                        checklist: false,
                    },
                ],
            },
            excavation: {
                detail_lokasi: null,
                detail_pekerjaan: null,
                work_duration: null,
                work_duration_sampai: null,
                tools: null,
                tanggal: null,
                apakah_harus_dihadiri_pengawas: "NA",
                vendor_representative: null,
                checklist_persiapan: [
                    {
                        label: "Apakah ada kabel bawah tanah dan sudah diidentifikasi lokasinya?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah lokasi kabel bawah tanah sudah diberi tanda?",
                        checklist: "NA",
                    },
                    {
                        label: " Apakah ada pipa/saluran bawah tanah?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah lokasi pipa/saluran bawah tanah sudah diberi tanda?",
                        checklist: "NA",
                    },
                    {
                        label: "Di sekitar lokasi yang akan digali telah diperiksa, apakah di dalam tanah terdapat kabel atau saluran pipa gas, minyak, air, jika ada, apakah aman untuk melakukan penggalian dan layanan bawah tanah telah terputus dan terbukti aman?",
                        checklist: "NA",
                    },
                    {
                        label: "Tangga untuk akses masuk ke galian tersedia dan telah diinspeksi?",
                        checklist: "NA",
                    },
                    {
                        label: "Tangga untuk akses masuk ke galian tersedia dan telah diinspeksi?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah semua tools dan equipment aman dan sesuai untuk pekerjaan?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah alat berat yang digunakan sudah teruji dan memiliki lisensi/hasil uji yang masih berlaku?",
                        checklist: "NA",
                    },
                    {
                        label: "Apakah pekerja yang melakukan pekerjaan memiliki kualifikasi K3 yang relevan?",
                        checklist: "NA",
                    },
                ],
                apd_yang_diperlukan: [
                    {
                        label: "Safety Helmet",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Safety Shoes",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Baju Kerja / Vest (rompi)",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Sarung Tangan Kerja",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Kacamata",
                        slug: "normal",
                        nama_lain: null,
                        checklist: false,
                    },
                    {
                        label: "Lainnya",
                        slug: "lainnya",
                        nama_lain: null,
                        checklist: false,
                    },
                ],
            },
            row_bahaya_tinggi: null,
            data_detail: null,
            work_permit_closing: [],
            work_type_data: "",

            today: moment().format("YYYY-MM-DD"),
            // variabel detail
            dokumen_proses: [],
        };
    },
    mounted() {
        let self = this;
        Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
            allowOutsideClick: false,
        });
        var config_type = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/work-permit-closed",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config_type)
            .then((response) => {
                self.work_permit_approved = response.data.data.referensi;
                // console.log(self.work_permit_approved);

                Swal.close();
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
                Swal.close();
            });
    },
    methods: {
        inputFileDocument(key_dokumen) {
            let self = this;
            if ($("#files-doc-" + key_dokumen)[0].files[0]) {
                if ($("#files-doc-" + key_dokumen)[0].files[0].size < 2242880) {
                    $("#duloading-" + key_dokumen).html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var attachment_document_in = this.file_evidence[key_dokumen];
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#files-doc-" + key_dokumen)[0].files[0]);
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/upload",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config)
                        .then(function (response) {
                            attachment_document_in.url = response.data.data.url;
                            urlres += response.data.data.url;
                            $("#duloading-" + key_dokumen).html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil</span>  <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> See Document</a></span>');
                            self.file_evidence[key_dokumen] = attachment_document_in;
                        })
                        .catch((e) => {
                            if (e?.response?.data?.data?.error) {
                                $("#duloading-" + key_dokumen).html('<span class="badge bg-danger p-1"><i class="fa fa-times"></i> ' + e?.response?.data?.data?.error + "</span> ");
                            }
                            console.log(e.response);
                        });
                } else {
                    alert("Max File 2 MB");
                }
            }
        },
        seeDocument(url) {
            window.open(process.env.VUE_APP_BACKEND_URL + url, "_blank");
        },
        addDokumenTerlampir() {
            this.file_evidence.push({
                nama_file: null,
                url: null,
            });
        },
        removeDokumenTerlampir(key_deleted) {
            this.file_evidence.splice(key_deleted, 1);
        },
        submitToData(status) {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            self.pdf_path = null;
            let required_form = new Promise(function (resolve, reject) {
                var is_lengkap = true;
                var message = [];
                // cek required
                if (status == "submited") {
                    if (!self.closing_statement) {
                        message.push("Closing Statement Required");
                        is_lengkap = false;
                    }
                    if (!self.selectedDeviation) {
                        message.push("Any Deviation Required");
                        is_lengkap = false;
                    }
                    console.log(self.file_evidence.length);
                    if (self.file_evidence.length == 0) {
                        message.push("Evidence File Required");
                        is_lengkap = false;
                    }
                    if (self.bahaya_tinggi[0].checklist) {
                        if (!self.work_at_height.detail_lokasi || self.work_at_height.detail_lokasi == null || self.work_at_height.detail_lokasi == "") {
                            message.push("Detail Lokasi Work at Height Required");
                            is_lengkap = false;
                        }
                        if (!self.work_at_height.detail_pekerjaan || self.work_at_height.detail_pekerjaan == null || self.work_at_height.detail_pekerjaan == "") {
                            message.push("Detail Pekerjaan Work at Height Required");
                            is_lengkap = false;
                        }
                        if (!self.work_at_height.tools || self.work_at_height.tools == null || self.work_at_height.tools == "") {
                            message.push("Tools / Equipment Required");
                            is_lengkap = false;
                        }
                        if (!self.work_at_height.vendor_representative || self.work_at_height.vendor_representative == null || self.work_at_height.vendor_representative == "") {
                            message.push("Vendor Representative Work at Height Required");
                            is_lengkap = false;
                        }
                        if (!self.work_at_height.tanggal || self.work_at_height.tanggal == null || self.work_at_height.tanggal == "") {
                            message.push("Tanggal Work at Height Required");
                            is_lengkap = false;
                        }
                        if (!self.work_at_height.work_duration || self.work_at_height.work_duration == null || self.work_at_height.work_duration == "") {
                            message.push("Durasi Work at Height Required");
                            is_lengkap = false;
                        }
                        if (!self.work_at_height.work_duration_sampai || self.work_at_height.work_duration_sampai == null || self.work_at_height.work_duration_sampai == "") {
                            message.push("Durasi sampai Duration Sampai Work at Height Required");
                            is_lengkap = false;
                        }

                        var cek_lampiran_work_at_height = false;
                        self.work_at_height.lampiran.forEach(function (val) {
                            if (val.checklist && !cek_lampiran_work_at_height) {
                                cek_lampiran_work_at_height = true;
                            }
                        });
                        if (!cek_lampiran_work_at_height) {
                            message.push("Lampiran Work at Height Required");
                            is_lengkap = false;
                        }

                        // apd work at height
                        var cek_apd_work_at_height = false;
                        self.work_at_height.apd_yang_diperlukan.forEach(function (val) {
                            if (val.checklist && !cek_apd_work_at_height) {
                                cek_apd_work_at_height = true;
                            }
                        });

                        if (!cek_apd_work_at_height) {
                            message.push("APD Yang Diperlukan Work at Height Required");
                            is_lengkap = false;
                        }
                    }
                    if (self.bahaya_tinggi[1].checklist) {
                        if (!self.hot_work.detail_lokasi || self.hot_work.detail_lokasi == null || self.hot_work.detail_lokasi == "") {
                            message.push("Detail Lokasi Hot work Required");
                            is_lengkap = false;
                        }
                        if (!self.hot_work.detail_hot_work || self.hot_work.detail_hot_work == null || self.hot_work.detail_hot_work == "") {
                            message.push("Detail Hot work Required");
                            is_lengkap = false;
                        }
                        if (!self.hot_work.vendor_representative || self.hot_work.vendor_representative == null || self.hot_work.vendor_representative == "") {
                            message.push("Vendor Representative Hot work Required");
                            is_lengkap = false;
                        }
                        if (!self.hot_work.work_duration || self.hot_work.work_duration == null || self.hot_work.work_duration == "") {
                            message.push("Durasi Hot work Required");
                            is_lengkap = false;
                        }
                        if (!self.hot_work.work_duration_sampai || self.hot_work.work_duration_sampai == null || self.hot_work.work_duration_sampai == "") {
                            message.push("Durasi sampai Duration Sampai Hot work Required");
                            is_lengkap = false;
                        }
                        if (!self.hot_work.spesifik_equipment || self.hot_work.spesifik_equipment == null || self.hot_work.spesifik_equipment == "") {
                            message.push("Peralatan Khusus Hot work Required");
                            is_lengkap = false;
                        }
                        var cek_lampiran_hot_work = false;
                        self.hot_work.lampiran.forEach(function (val) {
                            if (val.checklist && !cek_lampiran_hot_work) {
                                cek_lampiran_hot_work = true;
                            }
                        });
                        if (!cek_lampiran_hot_work) {
                            message.push("Lampiran Hot work Required");
                            is_lengkap = false;
                        }

                        var cek_apd_hot_work = false;
                        self.hot_work.apd_yang_diperlukan.forEach(function (val) {
                            if (val.checklist && !cek_apd_hot_work) {
                                cek_apd_hot_work = true;
                            }
                        });

                        if (!cek_apd_hot_work) {
                            message.push("APD Yang Diperlukan Hot work Required");
                            is_lengkap = false;
                        }
                        // hot_work.initial_test.hasil dan hot_work.initial_test.pembacaan harus disi semua

                        var cek_initial_test = false;
                        self.hot_work.initial_test.forEach(function (val) {
                            if (val.hasil && val.pembacaan && !cek_initial_test) {
                                cek_initial_test = true;
                            }
                        });

                        if (!cek_initial_test) {
                            message.push("Pembacaan dan hasil Gas Testing Hot work Required");
                            is_lengkap = false;
                        }

                        if (!self.hot_work.apakah_pekerjaan_dihadiri_fire_watch || self.hot_work.apakah_pekerjaan_dihadiri_fire_watch == null || self.hot_work.apakah_pekerjaan_dihadiri_fire_watch == "") {
                            message.push("Job Site Inspection Hot work Required");
                            is_lengkap = false;
                        }

                        if (!self.hot_work.nama_fire_watch || self.hot_work.nama_fire_watch == null || self.hot_work.nama_fire_watch == "") {
                            message.push("Nama Fire Watch Hot work Required");
                            is_lengkap = false;
                        }
                    }
                    if (self.bahaya_tinggi[2].checklist) {
                        if (!self.excavation.detail_lokasi || self.excavation.detail_lokasi == null || self.excavation.detail_lokasi == "") {
                            message.push("Detail Lokasi Excavation Required");
                            is_lengkap = false;
                        }
                        if (!self.excavation.tools || self.excavation.tools == null || self.excavation.tools == "") {
                            message.push("Tools / Equipment Excavation Required");
                            is_lengkap = false;
                        }
                        if (!self.excavation.vendor_representative || self.excavation.vendor_representative == null || self.excavation.vendor_representative == "") {
                            message.push("Vendor Representative Excavation Required");
                            is_lengkap = false;
                        }
                        if (!self.excavation.work_duration || self.excavation.work_duration == null || self.excavation.work_duration == "") {
                            message.push("Durasi Excavation Required");
                            is_lengkap = false;
                        }
                        if (!self.excavation.work_duration_sampai || self.excavation.work_duration_sampai == null || self.excavation.work_duration_sampai == "") {
                            message.push("Durasi sampai Duration Sampai Excavation Required");
                            is_lengkap = false;
                        }
                        var cek_apd_excavation = false;
                        self.excavation.apd_yang_diperlukan.forEach(function (val) {
                            if (val.checklist && !cek_apd_excavation) {
                                cek_apd_excavation = true;
                            }
                        });

                        if (!cek_apd_excavation) {
                            message.push("APD Yang Diperlukan Excavation Required");
                            is_lengkap = false;
                        }
                        if (!self.excavation.apakah_pekerjaan_dihadiri_fire_watch || self.excavation.apakah_pekerjaan_dihadiri_fire_watch == null || self.excavation.apakah_pekerjaan_dihadiri_fire_watch == "") {
                            message.push("Job Site Inspection Excavation Required");
                            is_lengkap = false;
                        }
                    }
                    if (self.bahaya_tinggi[3].checklist) {
                        // confined_space.detail_pekerjaan
                        // confined_space.detail_lokasi
                        // confined_space.tools
                        // confined_space.vendor_representative
                        // confined_space.work_duration
                        // confined_space.work_duration_sampai
                        // confined_space.apd_yang_diperlukan

                        if (!self.confined_space.detail_lokasi || self.confined_space.detail_lokasi == null || self.confined_space.detail_lokasi == "") {
                            message.push("Detail Lokasi Confined Space Required");
                            is_lengkap = false;
                        }
                        if (!self.confined_space.detail_pekerjaan || self.confined_space.detail_pekerjaan == null || self.confined_space.detail_pekerjaan == "") {
                            message.push("Detail Pekerjaan Confined Space Required");
                            is_lengkap = false;
                        }
                        if (!self.confined_space.tools || self.confined_space.tools == null || self.confined_space.tools == "") {
                            message.push("Tools / Equipment Confined Space Required");
                            is_lengkap = false;
                        }
                        if (!self.confined_space.vendor_representative || self.confined_space.vendor_representative == null || self.confined_space.vendor_representative == "") {
                            message.push("Vendor Representative Confined Space Required");
                            is_lengkap = false;
                        }
                        if (!self.confined_space.tanggal || self.confined_space.tanggal == null || self.confined_space.tanggal == "") {
                            message.push("Tanggal Confined Space Required");
                            is_lengkap = false;
                        }
                        if (!self.confined_space.work_duration || self.confined_space.work_duration == null || self.confined_space.work_duration == "") {
                            message.push("Durasi Confined Space Required");
                            is_lengkap = false;
                        }
                        if (!self.confined_space.work_duration_sampai || self.confined_space.work_duration_sampai == null || self.confined_space.work_duration_sampai == "") {
                            message.push("Durasi sampai Duration Sampai Confined Space Required");
                            is_lengkap = false;
                        }

                        // initial test confined space. hasil dan pembacaan harus di isi semua
                        var cek_initial_test_confined_space = false;
                        self.confined_space.initial_test.forEach(function (val) {
                            if (val.hasil && val.pembacaan) {
                                cek_initial_test_confined_space = true;
                            }
                        });

                        if (!cek_initial_test_confined_space) {
                            message.push("Pembacaan dan hasil Initial Gas Testing Confined Space Required");
                            is_lengkap = false;
                        }
                        var cek_final_test_confined_space = false;
                        self.confined_space.final_test.forEach(function (val) {
                            if (val.hasil && val.pembacaan) {
                                cek_final_test_confined_space = true;
                            }
                        });
                        if (!cek_final_test_confined_space) {
                            message.push("Pembacaan dan hasil Final Gas Testing Confined Space Required");
                            is_lengkap = false;
                        }
                        // for (let index = 0; index < self.confined_space.initial_test.length; index++) {
                        //     const element = self.confined_space.initial_test[index];
                        //     if (!element.hasil || !element.pembacaan || element.hasil == null || element.pembacaan == null || element.hasil == "" || element.pembacaan == "") {
                        //         message.push("Pembacaan dan hasil Initial Gas Testing Confined Space Required");
                        //         is_lengkap = false;
                        //     }
                        // }

                        // for (let index = 0; index < self.confined_space.final_test.length; index++) {
                        //     const element = self.confined_space.final_test[index];
                        //     if (!element.hasil || !element.pembacaan || element.hasil == null || element.pembacaan == null || element.hasil == "" || element.pembacaan == "") {
                        //         message.push("Pembacaan dan hasil Final Gas Testing Confined Space Required");
                        //         is_lengkap = false;
                        //     }
                        // }

                        var cek_lampiran_confined_space = false;
                        self.confined_space.lampiran.forEach(function (val) {
                            if (val.checklist && !cek_lampiran_confined_space) {
                                cek_lampiran_confined_space = true;
                            }
                        });
                        if (!cek_lampiran_confined_space) {
                            message.push("Lampiran Confined Space Required");
                            is_lengkap = false;
                        }

                        var cek_apd_confined_space = false;
                        self.confined_space.apd_yang_diperlukan.forEach(function (val) {
                            if (val.checklist && !cek_apd_confined_space) {
                                cek_apd_confined_space = true;
                            }
                        });

                        if (!cek_apd_confined_space) {
                            message.push("APD Yang Diperlukan Confined Space Required");
                            is_lengkap = false;
                        }
                        if (!self.confined_space.apakah_harus_dihadiri_pengawas) {
                            message.push("Job Site Inspection Confined Space Required");
                            is_lengkap = false;
                        }
                    }
                    if (self.bahaya_tinggi[4].checklist) {
                        if (!self.electrical.detail_lokasi) {
                            message.push("Detail Lokasi Electrical Required");
                            is_lengkap = false;
                        }
                        // electrical.tools
                        if (!self.electrical.tools) {
                            message.push("Tools / Equipment Electrical Required");
                            is_lengkap = false;
                        }
                        // electrical.vendor_representative
                        if (!self.electrical.vendor_representative) {
                            message.push("Vendor Representative Electrical Required");
                            is_lengkap = false;
                        }
                        // electrical.work_duration
                        if (!self.electrical.work_duration) {
                            message.push("Durasi Electrical Required");
                            is_lengkap = false;
                        }
                        // electrical.work_duration_sampai
                        if (!self.electrical.work_duration_sampai) {
                            message.push("Durasi sampai Duration Sampai Electrical Required");
                            is_lengkap = false;
                        }
                        // electrical.lampiran
                        var cek_lampiran_electrical = false;
                        self.electrical.lampiran.forEach(function (val) {
                            if (val.checklist && !cek_lampiran_electrical) {
                                cek_lampiran_electrical = true;
                            }
                        });
                        if (!cek_lampiran_electrical) {
                            message.push("Lampiran Electrical Required");
                            is_lengkap = false;
                        }
                        var cek_apd_electrical = false;
                        self.electrical.apd_yang_diperlukan.forEach(function (val) {
                            if (val.checklist && !cek_apd_electrical) {
                                cek_apd_electrical = true;
                            }
                        });
                        if (!cek_apd_electrical) {
                            message.push("APD Yang Diperlukan Electrical Required");
                            is_lengkap = false;
                        }
                        if (!self.electrical.apakah_harus_dihadiri_pengawas || self.electrical.apakah_harus_dihadiri_pengawas == null || self.electrical.apakah_harus_dihadiri_pengawas == "") {
                            message.push("Job Site Inspection Electrical Required");
                            is_lengkap = false;
                        }
                    }
                    if (self.bahaya_tinggi[5].checklist) {
                        if (!self.heavy_lifting.detail_lokasi) {
                            message.push("Detail Lokasi Heavy Lifting Required");
                            is_lengkap = false;
                        }
                        // heavy_lifting.tools

                        if (!self.heavy_lifting.peralatan_angkat) {
                            message.push("Peralatan angkut Heavy Lifting Required");
                            is_lengkap = false;
                        }

                        // heavy_lifting.vendor_representative
                        if (!self.heavy_lifting.vendor_representative) {
                            message.push("Vendor Representative Heavy Lifting Required");
                            is_lengkap = false;
                        }
                        // heavy_lifting.work_duration
                        if (!self.heavy_lifting.work_duration) {
                            message.push("Durasi Heavy Lifting Required");
                            is_lengkap = false;
                        }
                        // heavy_lifting.work_duration_sampai
                        if (!self.heavy_lifting.work_duration_sampai) {
                            message.push("Durasi sampai Duration Sampai Heavy Lifting Required");
                            is_lengkap = false;
                        }

                        // heavy_lifting.metode_pengangkatan
                        if (!self.heavy_lifting.metode_pengangkatan) {
                            message.push("Metode pengangkatan Heavy Lifting Required");
                            is_lengkap = false;
                        }
                        // heavy_lifting.lampiran
                        var cek_lampiran_heavy_lifting = false;
                        self.heavy_lifting.lampiran.forEach(function (val) {
                            if (val.checklist && !cek_lampiran_heavy_lifting) {
                                cek_lampiran_heavy_lifting = true;
                            }
                        });
                        if (!cek_lampiran_heavy_lifting) {
                            message.push("Lampiran Heavy Lifting Required");
                            is_lengkap = false;
                        }
                        var cek_apd_heavy_lifting = false;
                        self.heavy_lifting.apd_yang_diperlukan.forEach(function (val) {
                            if (val.checklist && !cek_apd_heavy_lifting) {
                                cek_apd_heavy_lifting = true;
                            }
                        });
                        if (!cek_apd_heavy_lifting) {
                            message.push("APD Yang Diperlukan Heavy Lifting Required");
                            is_lengkap = false;
                        }
                        if (!self.heavy_lifting.apakah_harus_dihadiri_pengawas || self.heavy_lifting.apakah_harus_dihadiri_pengawas == null || self.heavy_lifting.apakah_harus_dihadiri_pengawas == "") {
                            message.push("Job Site Inspection Heavy Lifting Required");
                            is_lengkap = false;
                        }
                    }

                    if (!is_lengkap) {
                        var list_error = "<ul class='text-start'>";
                        message.forEach(function (currentElement) {
                            list_error += "<li>" + currentElement + "</li>";
                        });
                        list_error += "</ul>";
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            html: list_error,
                        });
                        reject("stop");
                    }
                }
                resolve("next");
            });
            required_form.then(function (response) {
                if (response == "stop") {
                    return false;
                }
                var permit_detail = {
                    hot_work: self.hot_work,
                    work_at_height: self.work_at_height,
                    electrical: self.electrical,
                    confined_space: self.confined_space,
                    heavy_lifting: self.heavy_lifting,
                    excavation: self.excavation,
                    overtime: self.overtime,
                };
                var posible_hazard = {
                    kemungkinan_bahaya: self.kemungkinan_bahaya,
                    bahaya_rendah: self.bahaya_rendah,
                    bahaya_tinggi: self.bahaya_tinggi,
                    tindakan_pencegahan: self.tindakan_pencegahan,
                    apd_yang_diperlukan: self.apd_yang_diperlukan,
                    is_bahaya_rendah: self.is_bahaya_rendah,
                    is_bahaya_tinggi: self.is_bahaya_tinggi,
                };
                var datasubmit = {
                    work_permit_id: self.work_permit_selected?.id,
                    file_evidence: self.file_evidence,
                    closing_statement: self.closing_statement,
                    status: "draft",
                    permit_detail: permit_detail,
                    posible_hazard: posible_hazard,
                    deviation: self.selectedDeviation,
                };
                var config_store_data = null;

                if (self.closing_id) {
                    datasubmit.closing_id = self.closing_id;
                    config_store_data = {
                        method: "put",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit-closing",
                        data: datasubmit,
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    };
                } else {
                    config_store_data = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit-closing",
                        data: datasubmit,
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    };
                }

                axios(config_store_data)
                    .then(function (response) {
                        console.log(response);

                        if (status == "draft") {
                            let timerInterval;
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: "You will direct to page work permit request soon.",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    self.$router.push({ name: "work-permit-closing" });
                                }
                            });
                        } else {
                            Swal.close();
                            var res_fix = response.data.data[0];
                            console.log(res_fix);
                            self.detail_data_draft = res_fix;
                            self.id_permit = res_fix.work_permit_request_id;
                            self.closing_id = res_fix.id;
                            var pdf_path = process.env.VUE_APP_BACKEND_URL_VERSION + "generate/work-permit?id=" + res_fix.work_permit_request_id;
                            self.pdf_path = pdf_path;
                            self.status_modal_submit = "esign";
                            self.modal_submit = true;
                        }
                    })
                    .catch(function (error) {
                        this.axiosCatchError = error.response.data.data.data;
                        Swal.close();
                    });
            });
        },
        esignPDF() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            self.pdf_path = null;
            var permit_detail = {
                hot_work: self.hot_work,
                work_at_height: self.work_at_height,
                electrical: self.electrical,
                confined_space: self.confined_space,
                heavy_lifting: self.heavy_lifting,
                excavation: self.excavation,
                overtime: self.overtime,
            };
            var posible_hazard = {
                kemungkinan_bahaya: self.kemungkinan_bahaya,
                bahaya_rendah: self.bahaya_rendah,
                bahaya_tinggi: self.bahaya_tinggi,
                tindakan_pencegahan: self.tindakan_pencegahan,
                apd_yang_diperlukan: self.apd_yang_diperlukan,
                is_bahaya_rendah: self.is_bahaya_rendah,
                is_bahaya_tinggi: self.is_bahaya_tinggi,
            };
            var datasubmit = {
                closing_id: self.closing_id,
                work_permit_id: self.work_permit_selected?.id,
                file_evidence: self.file_evidence,
                closing_statement: self.closing_statement,
                status: "submited",
                permit_detail: permit_detail,
                posible_hazard: posible_hazard,
                deviation: self.selectedDeviation,
            };
            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit-closing",
                data: datasubmit,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    // console.log(response);

                    // Swal.close();
                    // var res_fix = response.data.data[0];
                    // console.log(res_fix);
                    // self.detail_data_draft = res_fix;
                    // self.id_permit = res_fix.work_permit_request_id;
                    // self.closing_id = res_fix.id;
                    // var pdf_path = process.env.VUE_APP_BACKEND_URL_VERSION + "generate/work-permit?id=" + res_fix.work_permit_request_id;
                    // self.pdf_path = pdf_path;
                    // self.optionAdministrator = response.data.data[1];
                    // self.status_modal_submit = "pilih_administrator";
                    console.log(response);
                    self.modal_submit = false;
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "You will direct to page work permit request soon.",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "work-permit-closing" });
                        }
                    });
                })
                .catch(function (error) {
                    this.axiosCatchError = error.response.data.data.data;
                    Swal.close();
                });
        },
        SubmitAdministrator() {
            let self = this;
            var config_store_data = null;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var datasubmit = {
                id: self.id_permit,
                closing_id: self.closing_id,
                administrator: self.administratorSelected,
                deviation: self.selectedDeviation,
            };
            config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit-closing/submit-administrator",
                data: datasubmit,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    self.modal_submit = false;
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "You will direct to page work permit request soon.",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "work-permit-closing" });
                        }
                    });
                })
                .catch(function (error) {
                    this.axiosCatchError = error.response.data.data.data;
                    Swal.close();
                });
        },
        UpdateDeviation() {
            if (this.selectedDeviation.value == true) {
                this.showPossibleHazard = true;
            } else {
                this.showPossibleHazard = false;
            }
            var detail_request = JSON.parse(this.work_permit_selected.detail_request);
            var attendance = detail_request.attendance;
            var performing_task = attendance.performing_task;
            var ot_performing_task = [];
            performing_task.forEach(function (currentElement) {
                ot_performing_task.push({
                    nama: currentElement.nama,
                    perusahaan: currentElement.perusahaan,
                    is_disabled: true,
                });
            });
            this.overtime.performing_task = ot_performing_task;

            console.log(this.showPossibleHazard);
            console.log(this.selectedDeviation);
        },
        addBahayaTinggi() {
            this.row_bahaya_tinggi.push({
                label: null,
                slug: null,
                checklist: false,
                nama_bahaya: null,
            });
        },
        removeBahayaTinggi(key_deleted) {
            this.bahaya_tinggi.splice(key_deleted, 1);
        },
        UpdateWorkSelected() {
            let self = this;
            if (self.work_permit_selected?.work_permit_no) {
                // Swal.fire({
                //     title: '<i class="fas fa-spinner fa-spin"></i>',
                //     text: "Loading...",
                //     showConfirmButton: false,
                //     allowOutsideClick: false,
                // });
                self.showloading = true;

                axios
                    .get(process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/detail", {
                        params: {
                            id: self.work_permit_selected?.id,
                        },
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    })
                    .then((response) => {
                        var response_data = response.data;
                        var response_data_fix = response_data.data;
                        console.log(response_data_fix);
                        if (response_data.meta.code == 200) {
                            var data_edit = response_data_fix.work_permit;
                            this.data_detail = data_edit;
                            this.hak_akses = response_data_fix.hak_akses;
                            this.status_sekarang = response_data_fix.status_proses;
                            this.timeline = response_data_fix?.work_permit?.work_permit_request_approval;
                            this.dokumen_proses = response_data_fix?.work_permit?.work_permit_request_dokumen;
                            if (data_edit.work_permit_closing) {
                                this.work_permit_closing = data_edit.work_permit_closing;
                                var closing_detail = JSON.parse(data_edit.work_permit_closing.detail_closing);
                                this.work_permit_closing_detail = closing_detail;
                                this.hak_akses_closing = response_data_fix.hak_akses_closing;
                                this.status_sekarang_closing = response_data_fix.status_proses_closing;
                            }

                            // possible hazards
                            // var detail_request = JSON.parse(data_edit.detail_request);
                            // var posible_hazard = detail_request.posible_hazard;
                            // self.is_bahaya_rendah = posible_hazard.is_bahaya_rendah;
                            // self.is_bahaya_tinggi = posible_hazard.is_bahaya_tinggi;
                            // self.kemungkinan_bahaya = posible_hazard.kemungkinan_bahaya;
                            // self.bahaya_rendah = posible_hazard.bahaya_rendah;
                            // self.bahaya_tinggi = posible_hazard.bahaya_tinggi;
                            // self.tindakan_pencegahan = posible_hazard.tindakan_pencegahan;
                            // self.apd_yang_diperlukan = posible_hazard.apd_yang_diperlukan;
                            // work type
                            this.work_type_data = data_edit.work_permit_type;
                            this.work_type_selected = data_edit.type;
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: response_data_fix.data.message,
                            });
                        }
                    });
            }
        },
        addLampiranBahayaTinggi(slug_bahaya, key_dokumen) {
            if (slug_bahaya == "work_at_height") {
                this.work_at_height.lampiran[key_dokumen].file.push({
                    nama_file: null,
                    url: null,
                });
            }
            if (slug_bahaya == "hot_work") {
                this.hot_work.lampiran[key_dokumen].file.push({
                    nama_file: null,
                    url: null,
                });
            }
            if (slug_bahaya == "confined_space") {
                this.confined_space.lampiran[key_dokumen].file.push({
                    nama_file: null,
                    url: null,
                });
            }
            if (slug_bahaya == "electrical") {
                this.electrical.lampiran[key_dokumen].file.push({
                    nama_file: null,
                    url: null,
                });
            }
            if (slug_bahaya == "heavy_lifting") {
                this.heavy_lifting.lampiran[key_dokumen].file.push({
                    nama_file: null,
                    url: null,
                });
            }
        },
        removeLampiranBahayaTinggi(slug_bahaya, key_dokumen, key_deleted) {
            if (slug_bahaya == "work_at_height") {
                this.work_at_height.lampiran[key_dokumen].file.splice(key_deleted, 1);
            }
            if (slug_bahaya == "hot_work") {
                this.hot_work.lampiran[key_dokumen].file.splice(key_deleted, 1);
            }
            if (slug_bahaya == "confined_space") {
                this.confined_space.lampiran[key_dokumen].file.splice(key_deleted, 1);
            }
            if (slug_bahaya == "electrical") {
                this.electrical.lampiran[key_dokumen].file.splice(key_deleted, 1);
            }
            if (slug_bahaya == "heavy_lifting") {
                this.heavy_lifting.lampiran[key_dokumen].file.splice(key_deleted, 1);
            }
        },
        inputFileDocumentWorkAtHeight(key_dokumen, key_file) {
            let self = this;
            if ($("#files-doc-lampiran-wah-" + key_dokumen + "-" + key_file)[0].files[0]) {
                if ($("#files-doc-lampiran-wah-" + key_dokumen + "-" + key_file)[0].files[0].size < 2242880) {
                    $("#duloading-lampiran-wah-" + key_dokumen + "-" + key_file).html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var attachment_document_in = this.work_at_height.lampiran[key_dokumen].file[key_file];
                    console.log(attachment_document_in);
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#files-doc-lampiran-wah-" + key_dokumen + "-" + key_file)[0].files[0]);
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/upload",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config)
                        .then(function (response) {
                            attachment_document_in.url = response.data.data.url;
                            urlres += response.data.data.url;
                            $("#duloading-lampiran-wah-" + key_dokumen + "-" + key_file).html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> See Document</a>');
                            self.work_at_height.lampiran[key_dokumen].file[key_file] = attachment_document_in;
                        })
                        .catch((e) => {
                            if (e?.response?.data?.data?.error) {
                                $("#duloading-lampiran-wah-" + key_dokumen + "-" + key_file).html('<span class="badge bg-danger p-1"><i class="fa fa-times"></i> ' + e?.response?.data?.data?.error + "</span> ");
                            }
                            console.log(e);
                        });
                } else {
                    alert("Max File 2 MB");
                }
            }
        },
        inputFileDocumentHotWork(key_dokumen, key_file) {
            let self = this;
            if ($("#files-doc-lampiran-hw-" + key_dokumen + "-" + key_file)[0].files[0]) {
                if ($("#files-doc-lampiran-hw-" + key_dokumen + "-" + key_file)[0].files[0].size < 2242880) {
                    $("#duloading-lampiran-hw-" + key_dokumen + "-" + key_file).html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var attachment_document_in = this.hot_work.lampiran[key_dokumen].file[key_file];
                    console.log(attachment_document_in);
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#files-doc-lampiran-hw-" + key_dokumen + "-" + key_file)[0].files[0]);
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/upload",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config)
                        .then(function (response) {
                            attachment_document_in.url = response.data.data.url;
                            urlres += response.data.data.url;
                            $("#duloading-lampiran-hw-" + key_dokumen + "-" + key_file).html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> See Document</a>');
                            self.hot_work.lampiran[key_dokumen].file[key_file] = attachment_document_in;
                        })
                        .catch((e) => {
                            if (e?.response?.data?.data?.error) {
                                $("#duloading-lampiran-hw-" + key_dokumen + "-" + key_file).html('<span class="badge bg-danger p-1"><i class="fa fa-times"></i> ' + e?.response?.data?.data?.error + "</span> ");
                            }
                            console.log(e);
                        });
                } else {
                    alert("Max File 2 MB");
                }
            }
        },
        inputFileDocumentconfined_space(key_dokumen, key_file) {
            let self = this;
            if ($("#files-doc-lampiran-confined_space-" + key_dokumen + "-" + key_file)[0].files[0]) {
                if ($("#files-doc-lampiran-confined_space-" + key_dokumen + "-" + key_file)[0].files[0].size < 2242880) {
                    $("#duloading-lampiran-confined_space-" + key_dokumen + "-" + key_file).html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var attachment_document_in = this.confined_space.lampiran[key_dokumen].file[key_file];
                    console.log(attachment_document_in);
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#files-doc-lampiran-confined_space-" + key_dokumen + "-" + key_file)[0].files[0]);
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/upload",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config)
                        .then(function (response) {
                            attachment_document_in.url = response.data.data.url;
                            urlres += response.data.data.url;
                            $("#duloading-lampiran-confined_space-" + key_dokumen + "-" + key_file).html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> See Document</a>');
                            self.confined_space.lampiran[key_dokumen].file[key_file] = attachment_document_in;
                        })
                        .catch((e) => {
                            if (e?.response?.data?.data?.error) {
                                $("#duloading-lampiran-confined_space-" + key_dokumen + "-" + key_file).html('<span class="badge bg-danger p-1"><i class="fa fa-times"></i> ' + e?.response?.data?.data?.error + "</span> ");
                            }
                            console.log(e);
                        });
                } else {
                    alert("Max File 2 MB");
                }
            }
        },
        inputFileDocumentElectrical(key_dokumen, key_file) {
            let self = this;
            if ($("#files-doc-lampiran-electrical-" + key_dokumen + "-" + key_file)[0].files[0]) {
                if ($("#files-doc-lampiran-electrical-" + key_dokumen + "-" + key_file)[0].files[0].size < 2242880) {
                    $("#duloading-lampiran-electrical-" + key_dokumen + "-" + key_file).html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var attachment_document_in = this.electrical.lampiran[key_dokumen].file[key_file];
                    console.log(attachment_document_in);
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#files-doc-lampiran-electrical-" + key_dokumen + "-" + key_file)[0].files[0]);
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/upload",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config)
                        .then(function (response) {
                            attachment_document_in.url = response.data.data.url;
                            urlres += response.data.data.url;
                            $("#duloading-lampiran-electrical-" + key_dokumen + "-" + key_file).html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> See Document</a>');
                            self.electrical.lampiran[key_dokumen].file[key_file] = attachment_document_in;
                        })
                        .catch((e) => {
                            if (e?.response?.data?.data?.error) {
                                $("#duloading-lampiran-electrical-" + key_dokumen + "-" + key_file).html('<span class="badge bg-danger p-1"><i class="fa fa-times"></i> ' + e?.response?.data?.data?.error + "</span> ");
                            }
                            console.log(e);
                        });
                } else {
                    alert("Max File 2 MB");
                }
            }
        },
        inputFileDocumentheavy_lifting(key_dokumen, key_file) {
            let self = this;
            if ($("#files-doc-lampiran-heavy_lifting-" + key_dokumen + "-" + key_file)[0].files[0]) {
                if ($("#files-doc-lampiran-heavy_lifting-" + key_dokumen + "-" + key_file)[0].files[0].size < 2242880) {
                    $("#duloading-lampiran-heavy_lifting-" + key_dokumen + "-" + key_file).html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var attachment_document_in = this.heavy_lifting.lampiran[key_dokumen].file[key_file];
                    console.log(attachment_document_in);
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#files-doc-lampiran-heavy_lifting-" + key_dokumen + "-" + key_file)[0].files[0]);
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/upload",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config)
                        .then(function (response) {
                            attachment_document_in.url = response.data.data.url;
                            urlres += response.data.data.url;
                            $("#duloading-lampiran-heavy_lifting-" + key_dokumen + "-" + key_file).html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> See Document</a>');
                            self.heavy_lifting.lampiran[key_dokumen].file[key_file] = attachment_document_in;
                        })
                        .catch((e) => {
                            if (e?.response?.data?.data?.error) {
                                $("#duloading-lampiran-heavy_lifting-" + key_dokumen + "-" + key_file).html('<span class="badge bg-danger p-1"><i class="fa fa-times"></i> ' + e?.response?.data?.data?.error + "</span> ");
                            }
                            console.log(e);
                        });
                } else {
                    alert("Max File 2 MB");
                }
            }
        },
        checkDokumenLampiran(key_lampiran) {
            let self = this;
            var dokumen_terlampir_used = self.dokumen_terlampir[key_lampiran];
            if (dokumen_terlampir_used.slug == "attendance" && dokumen_terlampir_used.checklist) {
                self.attendance_modal = true;
            }
            if (dokumen_terlampir_used.slug == "overtime" && dokumen_terlampir_used.checklist) {
                self.overtime_modal = true;
            }
        },
        addOvertimePerformingTask() {
            this.overtime.performing_task.push({
                nama: null,
                perusahaan: null,
                is_disabled: false,
            });
        },
        removeOvertimePerformingTask(key_deleted) {
            this.overtime.performing_task.splice(key_deleted, 1);
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" />
        <div class="row">
            <div class="col-lg-12">
                <b-card border-variant="dark" header="Work Permit Information">
                    <b-card-body>
                        <b-form-group label="Work Permit Approved" label-for="formrow-nama-role-input">
                            <v-select :options="work_permit_approved" label="work_permit_no" v-model="work_permit_selected" placeholder="Choose Work Permit Approved" @update:modelValue="UpdateWorkSelected"></v-select>
                        </b-form-group>
                        <table class="table table-sm table-striped table-borderles" v-if="work_permit_selected?.work_permit_no">
                            <tbody>
                                <tr>
                                    <th style="width: 250px">Work Permit Number</th>
                                    <th style="width: 10px">:</th>
                                    <td>{{ work_permit_selected?.work_permit_no }}</td>
                                </tr>
                                <tr>
                                    <th style="width: 250px">Work Permit Date</th>
                                    <th style="width: 10px">:</th>
                                    <td>{{ work_permit_selected?.work_permit_date }}</td>
                                </tr>
                                <tr>
                                    <th style="width: 250px">Initiator Name</th>
                                    <th style="width: 10px">:</th>
                                    <td>{{ work_permit_selected?.user?.name }}</td>
                                </tr>
                                <tr>
                                    <th style="width: 250px">Work Permit Status</th>
                                    <th style="width: 10px">:</th>
                                    <td>{{ work_permit_selected?.status_permit }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-card-body>
                </b-card>
                <b-tabs content-class="text-muted" v-if="work_permit_selected?.work_permit_no">
                    <b-tab class="border-0">
                        <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">Work Permit Detail</span>
                        </template>
                        <div v-if="work_type_data === 'General'">
                            <generalForm :data_detail="data_detail" :work_permit_type="work_type_selected" aksi="detail" />
                        </div>
                        <div v-else-if="work_type_data === 'Working at Height'">
                            <workingHeightForm />
                        </div>
                        <div v-else-if="work_type_data === 'Excavation'">
                            <excavationForm />
                        </div>
                        <div v-else-if="work_type_data === 'Confined Space'">
                            <confinedSpaceForm />
                        </div>
                        <div v-else-if="work_type_data === 'Electrical'">
                            <energizedForm />
                        </div>
                        <div v-else-if="work_type_data === 'Hot'">
                            <hotForm />
                        </div>
                        <b-card border-variant="dark" header="Dokumen Proses">
                            <b-card-body>
                                <div class="row mb-3">
                                    <div class="col-md-12">
                                        <table class="table table-sm table-bordered mb-0">
                                            <thead class="bg-dark text-white text-center">
                                                <tr>
                                                    <th>No</th>
                                                    <th>Nama Dokumen</th>
                                                    <th>File</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="dokumen_proses.length == 0">
                                                    <td colspan="3">Data Belum Tersedia</td>
                                                </tr>
                                                <tr v-for="(dokumen_proses_val, dokumen_proses_key) in dokumen_proses" :key="dokumen_proses_key" v-else>
                                                    <td>{{ dokumen_proses_key + 1 }}</td>
                                                    <td>{{ dokumen_proses_val.nama_file }}</td>
                                                    <td>
                                                        <div class="btn btn-warning btn-sm" v-on:click="getFile(dokumen_proses_val.path)"><i class="fa fa-eye"></i> Lihat</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="row" v-if="hak_akses && status_sekarang?.mspk_status_kategori != 'Selesai'">
                                    <div class="col-md-12 text-end" v-if="status_sekarang?.is_approval">
                                        <div class="btn btn-danger ms-1" @click="submitProcess('tolak')"><i class="bx bx-x"></i> Tolak</div>
                                        <div class="btn btn-primary ms-1" @click="submitProcess('terima')"><i class="bx bx-check"></i> Terima</div>
                                    </div>
                                    <div class="col-md-12 text-end" v-else>
                                        <div class="btn btn-primary ms-1" @click="submitProcess('terima')"><i class="bx bx-save"></i> Submit</div>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-tab>
                    <b-tab active>
                        <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                                <i class="far fa-user"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">Work Permit Closing</span>
                        </template>
                        <b-card border-variant="dark" header="Work Permit Closing">
                            <b-card-body>
                                <div class="mb-3">
                                    <label class="d-block">Any Deviation <span class="text-danger">*</span></label>
                                    <v-select :options="optionsDeviation" label="label" v-model="selectedDeviation" placeholder="Choose Deviation" value="value" @update:modelValue="UpdateDeviation()"></v-select>
                                </div>
                                <div class="mb-3">
                                    <label class="d-block">Evidence File <span class="text-danger">*</span></label>
                                    <table class="table table-sm table-bordered mb-0">
                                        <thead class="bg-dark text-white text-center">
                                            <tr>
                                                <th>No</th>
                                                <th>Nama File</th>
                                                <th>File</th>
                                                <th>
                                                    <div class="btn btn-sm btn-success bg-success" @click="addDokumenTerlampir()">
                                                        <i class="bx bx-plus"></i>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row_dt, key_dt) in file_evidence" :key="key_dt">
                                                <td>{{ key_dt + 1 }}</td>
                                                <td>
                                                    <input type="text" class="form-control mb-0 form-control-sm" v-model="row_dt.nama_file" />
                                                </td>
                                                <td>
                                                    <input type="file" class="form-control mb-0 form-control-sm" :id="'files-doc-' + key_dt" v-on:change="inputFileDocument(key_dt)" accept=".pdf, .word, .excel, .png, .jpg" />
                                                    <div class="respond-input-file float-left" :id="'duloading-' + key_dt">
                                                        <div v-if="row_dt.url && aksi == 'edit'">
                                                            <span class="badge bg-success p-1" @click="seeDocument(row_dt.url)"><i class="fa fa-eye"></i> See Document </span>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <small class="float-right">*) Max File 2 MB </small>
                                                </td>
                                                <td>
                                                    <div class="btn btn-danger btn-sm" v-on:click="removeDokumenTerlampir(key_dt)"><i class="bx bx-minus"></i></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="mb-3">
                                    <label class="d-block">Closing statement <span class="text-danger">*</span></label>
                                    <textarea v-model="closing_statement" class="form-control"></textarea>
                                </div>
                            </b-card-body>
                        </b-card>
                        <div v-if="showPossibleHazard == true">
                            <b-card border-variant="dark" header="Posible Hazard">
                                <b-card-body>
                                    <div class="mb-3">
                                        <label class="d-block">Overtime Permit</label>
                                        <div v-for="(row_data, key_data) in dokumen_terlampir" :key="key_data" class="col-md-4">
                                            <div class="form-check" v-if="row_data.slug == 'overtime'">
                                                <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" @change="checkDokumenLampiran(key_data)" />
                                                <label class="form-check-label" for=""> {{ row_data.label }} <span class="text-danger" v-if="row_data.is_required">*</span> </label>
                                                <div v-if="row_data.checklist">
                                                    <div class="btn btn-sm btn-warning bg-warning" @click="overtime_modal = true"><i class="bx bx-pencil"></i> Edit Overtime</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="d-block">Kemungkinan Bahaya <span class="text-danger">*</span> </label>
                                        <table style="width: 100%">
                                            <tr>
                                                <td>
                                                    <input class="form-check-input" type="checkbox" id="" v-model="is_bahaya_tinggi" />
                                                </td>
                                                <td>Bahaya Tinggi, Pilih Dibawah Ini</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <div class="row">
                                                        <div v-for="(row_bahaya_tinggi, key_bahaya_tinggi) in bahaya_tinggi" :key="key_bahaya_tinggi" class="col-md-3">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" id="" v-model="row_bahaya_tinggi.checklist" :disabled="!is_bahaya_tinggi" />
                                                                <label class="form-check-label" for="">{{ row_bahaya_tinggi.label }} </label>
                                                                <div v-if="row_bahaya_tinggi.checklist && row_bahaya_tinggi.slug == 'lainnya'">
                                                                    <input type="text" v-model="row_bahaya_tinggi.nama_bahaya" class="form-control form-control-sm" placeholder="Sebutkan..." />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </b-card-body>
                            </b-card>

                            <b-card border-variant="dark" header="Working at Heights" v-if="bahaya_tinggi[0].checklist">
                                <b-card-body>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label class="d-block">Detail Lokasi Pekerjaan <span class="text-danger">*</span> </label>
                                            <input type="text" class="form-control" v-model="work_at_height.detail_lokasi" />
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Detail Pekerjaan <span class="text-danger">*</span> </label>
                                            <input type="text" class="form-control" v-model="work_at_height.detail_pekerjaan" />
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Tools / Equipment khusus untuk digunakan <span class="text-danger">*</span> </label>
                                            <input type="text" class="form-control" v-model="work_at_height.tools" />
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Vendor representative <span class="text-danger">*</span> </label>
                                            <textarea v-model="work_at_height.vendor_representative" class="form-control" placeholder="Vendor Representative"></textarea>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Tanggal <span class="text-danger">*</span> </label>
                                            <input type="date" class="form-control" v-model="work_at_height.tanggal" :min="today" />
                                        </div>

                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="d-block">Durasi Kerja</label>
                                                        <input placeholder="Durasi Kerja" type="time" v-model="work_at_height.work_duration" class="form-control" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="d-block">Hingga</label>
                                                        <input placeholder="Hingga" type="time" v-model="work_at_height.work_duration_sampai" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <h4>Measures To Be Taken</h4>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">System Preparation</label>
                                            <table class="table table-sm table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Item untuk dicek</th>
                                                        <th>Ya / Tidak / NA</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(data_cp, key_cp) in work_at_height.checklist_persiapan" :key="key_cp">
                                                        <td>
                                                            {{ key_cp + 1 }}
                                                        </td>
                                                        <td>
                                                            <div v-html="data_cp.label"></div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Ya" v-model="data_cp.checklist" />
                                                                <label class="form-check-label" for=""> Ya </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Tidak" v-model="data_cp.checklist" />
                                                                <label class="form-check-label" for=""> Tidak </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="NA" v-model="data_cp.checklist" />
                                                                <label class="form-check-label" for=""> NA </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Lampiran <span class="text-danger">*</span> </label>
                                            <div class="row">
                                                <div v-for="(row_data, key_data) in work_at_height.lampiran" :key="key_data" class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" />
                                                        <label class="form-check-label" for="">
                                                            {{ row_data.label }}
                                                        </label>
                                                        <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                            <input type="text" v-model="row_data.nama_lain" class="form-control form-control-sm" placeholder="Sebutkan..." />
                                                        </div>
                                                        <div v-if="row_data.checklist">
                                                            <table class="table table-sm table-border">
                                                                <thead>
                                                                    <tr>
                                                                        <th>No</th>
                                                                        <th>Nama File</th>
                                                                        <th>File</th>
                                                                        <th>
                                                                            <div class="btn btn-sm btn-success bg-success" @click="addLampiranBahayaTinggi('work_at_height', key_data)">
                                                                                <i class="bx bx-plus"></i>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(row_dt, key_dt) in row_data.file" :key="key_dt">
                                                                        <td>{{ key_dt + 1 }}</td>
                                                                        <td>
                                                                            <input type="text" class="form-control mb-0 form-control-sm" v-model="row_dt.nama_file" />
                                                                        </td>
                                                                        <td>
                                                                            <input type="file" class="form-control mb-0 form-control-sm" :id="'files-doc-lampiran-wah-' + key_data + '-' + key_dt" v-on:change="inputFileDocumentWorkAtHeight(key_data, key_dt)" accept=".pdf, .word, .excel, .png, .jpg" />
                                                                            <div class="respond-input-file float-left" :id="'duloading-lampiran-wah-' + key_data + '-' + key_dt">
                                                                                <div v-if="row_data.file[0].url && aksi == 'edit'">
                                                                                    <span class="badge bg-success p-1" @click="seeDocument(row_data.file[0].url)"><i class="fa fa-eye"></i> See Document </span>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <small class="float-right">*) Max File 2 MB </small>
                                                                        </td>
                                                                        <td>
                                                                            <div class="btn btn-danger btn-sm" v-on:click="removeLampiranBahayaTinggi('work_at_height', key_data, key_dt)"><i class="bx bx-minus"></i></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">APD yang diperlukan</label>
                                            <div class="row">
                                                <div v-for="(row_data, key_data) in work_at_height.apd_yang_diperlukan" :key="key_data" class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" />
                                                        <label class="form-check-label" for="">
                                                            {{ row_data.label }}
                                                        </label>
                                                        <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                            <input type="text" v-model="row_data.nama_lain" class="form-control form-control-sm" placeholder="Sebutkan..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <h4>Acceptance</h4>
                                        </div>
                                        <div class="col-md-12">
                                            <label for="" class="d-block">Job Site Inspection / Implementation of Safety Measures</label>
                                            <table class="table table-sm table-bordered table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th>Apakah pekerjaan ini harus dihadiri oleh pengawas?</th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="At Job Start" v-model="work_at_height.apakah_harus_dihadiri_pengawas" />
                                                                <label class="form-check-label" for=""> At Job Start </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Continuously" v-model="work_at_height.apakah_harus_dihadiri_pengawas" />
                                                                <label class="form-check-label" for=""> Continuously </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="No" v-model="work_at_height.apakah_harus_dihadiri_pengawas" />
                                                                <label class="form-check-label" for=""> No </label>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-card>
                            <b-card border-variant="dark" header="Hot Work" v-if="bahaya_tinggi[1].checklist">
                                <b-card-body>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-form-group label="Detail Lokasi Pekerjaan" label-for="formrow-nama-role-input">
                                                <b-form-input id="formrow-slug-role-input" placeholder="Detail Lokasi Pekerjaan" type="text" v-model="hot_work.detail_lokasi"></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <b-form-group label="Detail Hot Work yang Dilakukan (Cutting/Grinding/Welding/Lainnya)" label-for="formrow-nama-role-input">
                                                <b-form-input id="formrow-slug-role-input" placeholder="Detail Hot Work yang Dilakukan" type="text" v-model="hot_work.detail_hot_work"></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <label class="d-block">Vendor representative </label>
                                                <textarea v-model="hot_work.vendor_representative" class="form-control" placeholder="Vendor Representative"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <label class="d-block">Tanggal</label>
                                                <input placeholder="Tanggal" type="date" v-model="hot_work.tanggal" :min="today" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="d-block">Dimulai Pada Pukul</label>
                                                <input placeholder="Dimulai Pada Pukul" type="time" v-model="hot_work.work_duration" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="d-block">Hingga</label>
                                                <input placeholder="Hingga" type="time" v-model="hot_work.work_duration_sampai" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <b-form-group label="Spesifik Equipment atau Vessel / Pekerjaan dilaksanakan" label-for="formrow-nama-role-input">
                                                <b-form-input id="formrow-slug-role-input" placeholder="Spesifik Equipment atau Vessel / Pekerjaan dilaksanakan" type="text" v-model="hot_work.spesifik_equipment"></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <h4>Measures To Be Taken</h4>
                                        </div>
                                        <div class="col-md-12">
                                            <b-form-group label="Persiapan" label-for="formrow-nama-role-input">
                                                <table class="table table-sm table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>No</th>
                                                            <th>Item untuk dicek</th>
                                                            <th>Ya / Tidak / NA</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(data_cp, key_cp) in hot_work.checklist_persiapan" :key="key_cp">
                                                            <td>
                                                                {{ key_cp + 1 }}
                                                            </td>
                                                            <td>
                                                                <div v-html="data_cp.label"></div>
                                                            </td>
                                                            <td>
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="data_cp.checklist" />
                                                                    <label class="form-check-label" for=""> Ya </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="data_cp.checklist" />
                                                                    <label class="form-check-label" for=""> Tidak </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="NA" v-model="data_cp.checklist" />
                                                                    <label class="form-check-label" for=""> NA </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Lampiran <span class="text-danger">*</span> </label>
                                            <div class="row">
                                                <div v-for="(row_data, key_data) in hot_work.lampiran" :key="key_data" class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" />
                                                        <label class="form-check-label" for="">
                                                            {{ row_data.label }}
                                                        </label>
                                                        <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                            <input type="text" v-model="row_data.nama_lain" class="form-control form-control-sm" placeholder="Sebutkan..." />
                                                        </div>
                                                        <div v-if="row_data.checklist">
                                                            <table class="table table-sm table-border">
                                                                <thead>
                                                                    <tr>
                                                                        <th>No</th>
                                                                        <th>Nama File</th>
                                                                        <th>File</th>
                                                                        <th>
                                                                            <div class="btn btn-sm btn-success bg-success" @click="addLampiranBahayaTinggi('hot_work', key_data)">
                                                                                <i class="bx bx-plus"></i>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(row_dt, key_dt) in row_data.file" :key="key_dt">
                                                                        <td>{{ key_dt + 1 }}</td>
                                                                        <td>
                                                                            <input type="text" class="form-control mb-0 form-control-sm" v-model="row_dt.nama_file" />
                                                                        </td>
                                                                        <td>
                                                                            <input type="file" class="form-control mb-0 form-control-sm" :id="'files-doc-lampiran-hw-' + key_data + '-' + key_dt" v-on:change="inputFileDocumentHotWork(key_data, key_dt)" accept=".pdf, .word, .excel, .png, .jpg" />
                                                                            <div class="respond-input-file float-left" :id="'duloading-lampiran-hw-' + key_data + '-' + key_dt">
                                                                                <div v-if="row_data.file[0].url && aksi == 'edit'">
                                                                                    <span class="badge bg-success p-1" @click="seeDocument(row_data.file[0].url)"><i class="fa fa-eye"></i> See Document </span>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <small class="float-right">*) Max File 2 MB </small>
                                                                        </td>
                                                                        <td>
                                                                            <div class="btn btn-danger btn-sm" v-on:click="removeLampiranBahayaTinggi('hot_work', key_data, key_dt)"><i class="bx bx-minus"></i></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <b-form-group label="APD yang diperlukan (Lihat General permit)" label-for="formrow-nama-role-input">
                                                <div class="row">
                                                    <div v-for="(row_data, key_data) in hot_work.apd_yang_diperlukan" :key="key_data" class="col-md-4">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" />
                                                            <label class="form-check-label" for="">
                                                                {{ row_data.label }}
                                                            </label>
                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                <input type="text" v-model="row_data.nama_lain" class="form-control form-control-sm" placeholder="Sebutkan..." />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <b-form-group label="ATMOSPHERIC GAS TESTING (DIISI OLEH HSE)" label-for="formrow-nama-role-input">
                                                <table class="table table-sm table-bordered table-striped">
                                                    <tbody>
                                                        <tr>
                                                            <th>Apakah gas awal diperlukan</th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="hot_work.apakah_gas_awal_diperlukan" />
                                                                    <label class="form-check-label" for=""> Ya </label>
                                                                </div>
                                                            </th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="hot_work.apakah_gas_awal_diperlukan" />
                                                                    <label class="form-check-label" for=""> Tidak </label>
                                                                </div>
                                                            </th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="NA" v-model="hot_work.apakah_gas_awal_diperlukan" />
                                                                    <label class="form-check-label" for=""> NA </label>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Apakah pengetesan gas diperlukan saat pekerjaan?</th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Periodik" v-model="hot_work.apakah_pengetesan_gas_diperlukan" />
                                                                    <label class="form-check-label" for=""> Periodik </label>
                                                                </div>
                                                            </th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Terus-menerus" v-model="hot_work.apakah_pengetesan_gas_diperlukan" />
                                                                    <label class="form-check-label" for=""> Terus-menerus </label>
                                                                </div>
                                                            </th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="hot_work.apakah_pengetesan_gas_diperlukan" />
                                                                    <label class="form-check-label" for=""> Tidak </label>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Apakah instrument gas terkalibrasi dalam sebulan ini?</th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="hot_work.apakah_instrumen_gas_terkalibrasi" />
                                                                    <label class="form-check-label" for=""> Ya </label>
                                                                </div>
                                                            </th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="hot_work.apakah_instrumen_gas_terkalibrasi" />
                                                                    <label class="form-check-label" for=""> Tidak </label>
                                                                </div>
                                                            </th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="NA" v-model="hot_work.apakah_instrumen_gas_terkalibrasi" />
                                                                    <label class="form-check-label" for=""> NA </label>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Apakah gas testing dilakukan oleh orang kompeten?</th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="hot_work.apakah_gas_testing_dilakukan_oleh_orang_kompeten" />
                                                                    <label class="form-check-label" for=""> Ya </label>
                                                                </div>
                                                            </th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="hot_work.apakah_gas_testing_dilakukan_oleh_orang_kompeten" />
                                                                    <label class="form-check-label" for=""> Tidak </label>
                                                                </div>
                                                            </th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="NA" v-model="hot_work.apakah_gas_testing_dilakukan_oleh_orang_kompeten" />
                                                                    <label class="form-check-label" for=""> NA </label>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-sm table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Test</th>
                                                            <th>Pembacaan (Atas, Tengah, Bawah)</th>
                                                            <th>Hasil</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(val_initial, key_initial) in hot_work.initial_test" :key="key_initial">
                                                            <td>{{ val_initial.label }}</td>
                                                            <td>
                                                                <input type="text" v-model="val_initial.pembacaan" class="form-control form-control-sm" placeholder="Pembacaan" />
                                                            </td>
                                                            <td>
                                                                <input type="text" v-model="val_initial.hasil" class="form-control form-control-sm" placeholder="Hasil" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <h4>Measures To Be Taken</h4>
                                        </div>
                                        <div class="col-md-12">
                                            <b-form-group label="ATMOSPHERIC GAS TESTING (DIISI OLEH HSE)" label-for="formrow-nama-role-input">
                                                <table class="table table-sm table-bordered table-striped">
                                                    <tbody>
                                                        <tr>
                                                            <th>Apakah pekerjaan ini harus dihadiri oleh pengawas / Fire Watch?</th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="At Job Start" v-model="hot_work.apakah_pekerjaan_dihadiri_fire_watch" />
                                                                    <label class="form-check-label" for=""> At Job Start </label>
                                                                </div>
                                                            </th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Continuously" v-model="hot_work.apakah_pekerjaan_dihadiri_fire_watch" />
                                                                    <label class="form-check-label" for=""> Continuously </label>
                                                                </div>
                                                            </th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="No" v-model="hot_work.apakah_pekerjaan_dihadiri_fire_watch" />
                                                                    <label class="form-check-label" for=""> No </label>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Nama Fire Watch</th>
                                                            <td class="text-center" colspan="3">
                                                                <b-form-input id="formrow-slug-role-input" placeholder="Nama Fire Watch" type="text" v-model="hot_work.nama_fire_watch"></b-form-input>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-card>
                            <b-card border-variant="dark" header="Excavation Work" v-if="bahaya_tinggi[2].checklist">
                                <b-card-body>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-form-group label="Detail Lokasi Pekerjaan" label-for="formrow-nama-role-input">
                                                <b-form-input id="formrow-slug-role-input" placeholder="Detail Lokasi Pekerjaan" type="text" v-model="excavation.detail_lokasi"></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <b-form-group label="Tools / Equipment khusus untuk digunakan" label-for="formrow-nama-role-input">
                                                <b-form-input id="formrow-slug-role-input" placeholder="Tools / Equipment khusus" type="text" v-model="excavation.tools"></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <label class="d-block">Vendor representative </label>
                                                <textarea v-model="excavation.vendor_representative" class="form-control" placeholder="Vendor Representative"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <label class="d-block">Tanggal</label>
                                                <input placeholder="Tanggal" type="date" v-model="excavation.tanggal" :min="today" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="d-block">Dimulai Pada Pukul</label>
                                                <input placeholder="Dimulai Pada Pukul" type="time" v-model="excavation.work_duration" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="d-block">Hingga</label>
                                                <input placeholder="Hingga" type="time" v-model="excavation.work_duration_sampai" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <h4>Measures To Be Taken</h4>
                                        </div>
                                        <div class="col-md-12">
                                            <b-form-group label="Persiapan" label-for="formrow-nama-role-input">
                                                <table class="table table-sm table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>No</th>
                                                            <th>Item untuk dicek</th>
                                                            <th>Ya / Tidak / NA</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(data_cp, key_cp) in excavation.checklist_persiapan" :key="key_cp">
                                                            <td>
                                                                {{ key_cp + 1 }}
                                                            </td>
                                                            <td>
                                                                <div v-html="data_cp.label"></div>
                                                            </td>
                                                            <td>
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="data_cp.checklist" />
                                                                    <label class="form-check-label" for=""> Ya </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="data_cp.checklist" />
                                                                    <label class="form-check-label" for=""> Tidak </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="NA" v-model="data_cp.checklist" />
                                                                    <label class="form-check-label" for=""> NA </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </b-form-group>
                                        </div>

                                        <div class="col-md-12">
                                            <b-form-group label="APD yang diperlukan" label-for="formrow-nama-role-input">
                                                <div class="row">
                                                    <div v-for="(row_data, key_data) in excavation.apd_yang_diperlukan" :key="key_data" class="col-md-4">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" />
                                                            <label class="form-check-label" for="">
                                                                {{ row_data.label }}
                                                            </label>
                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                <input type="text" v-model="row_data.nama_lain" class="form-control form-control-sm" placeholder="Sebutkan..." />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <h4>Measures To Be Taken</h4>
                                        </div>
                                        <div class="col-md-12">
                                            <b-form-group>
                                                <table class="table table-sm table-bordered table-striped">
                                                    <tbody>
                                                        <tr>
                                                            <th>Apakah pekerjaan ini harus dihadiri oleh pengawas / Fire Watch?</th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="At Job Start" v-model="excavation.apakah_pekerjaan_dihadiri_fire_watch" />
                                                                    <label class="form-check-label" for=""> At Job Start </label>
                                                                </div>
                                                            </th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="Continuously" v-model="excavation.apakah_pekerjaan_dihadiri_fire_watch" />
                                                                    <label class="form-check-label" for=""> Continuously </label>
                                                                </div>
                                                            </th>
                                                            <th class="text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" value="No" v-model="excavation.apakah_pekerjaan_dihadiri_fire_watch" />
                                                                    <label class="form-check-label" for=""> No </label>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Nama Fire Watch</th>
                                                            <td class="text-center" colspan="3">
                                                                <b-form-input id="formrow-slug-role-input" placeholder="Nama Fire Watch" type="text" v-model="excavation.nama_fire_watch"></b-form-input>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-card>

                            <!-- Confined Space -->
                            <b-card border-variant="dark" header="Confined Space" v-if="bahaya_tinggi[3].checklist">
                                <b-card-body>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label class="d-block">Detail Pekerjaan <span class="text-danger">*</span> </label>
                                            <input type="text" class="form-control" v-model="confined_space.detail_pekerjaan" />
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Detail Lokasi Pekerjaan <span class="text-danger">*</span> </label>
                                            <input type="text" class="form-control" v-model="confined_space.detail_lokasi" />
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Tools / Equipment khusus untuk digunakan <span class="text-danger">*</span> </label>
                                            <input type="text" class="form-control" v-model="confined_space.tools" />
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Vendor representative <span class="text-danger">*</span> </label>
                                            <textarea v-model="confined_space.vendor_representative" class="form-control" placeholder="Vendor Representative"></textarea>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Tanggal <span class="text-danger">*</span> </label>
                                            <input type="date" class="form-control" v-model="confined_space.tanggal" :min="today" />
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="d-block">Durasi Kerja</label>
                                                        <input placeholder="Durasi Kerja" type="time" v-model="confined_space.work_duration" class="form-control" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="d-block">Hingga</label>
                                                        <input placeholder="Hingga" type="time" v-model="confined_space.work_duration_sampai" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <h4>Measures To Be Taken</h4>
                                        </div>
                                        <div class="col-md-12">
                                            <label for="">ATMOSPHERIC GAS TESTING</label>
                                            <table class="table table-sm table-bordered table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th>Apakah gas test awal diperlukan?</th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Ya" v-model="confined_space.apakah_tes_gas_awal_dipelukan" />
                                                                <label class="form-check-label" for=""> Ya </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center"></th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Tidak" v-model="confined_space.apakah_tes_gas_awal_dipelukan" />
                                                                <label class="form-check-label" for=""> Tidak </label>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Apakah pengetesan gas diperlukan saat pekerjaan?</th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Periodik" v-model="confined_space.apakah_pengetesan_gas_diperlukan_saat_pekerjaan" />
                                                                <label class="form-check-label" for=""> Periodik </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Terus-menerus" v-model="confined_space.apakah_pengetesan_gas_diperlukan_saat_pekerjaan" />
                                                                <label class="form-check-label" for=""> Terus-menerus </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Tidak" v-model="confined_space.apakah_pengetesan_gas_diperlukan_saat_pekerjaan" />
                                                                <label class="form-check-label" for=""> Tidak </label>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Apakah instrument gas terkalibrasi dalam sebulan ini?</th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Ya" v-model="confined_space.apakah_instrumen_gas_terkalibrasi" />
                                                                <label class="form-check-label" for=""> Ya </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Tidak" v-model="confined_space.apakah_instrumen_gas_terkalibrasi" />
                                                                <label class="form-check-label" for=""> Tidak </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="NA" v-model="confined_space.apakah_instrumen_gas_terkalibrasi" />
                                                                <label class="form-check-label" for=""> NA </label>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Apakah gas testing dilakukan oleh orang kompeten?</th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Ya" v-model="confined_space.apakah_gas_testing_dilakukan_oleh_orang_kompeten" />
                                                                <label class="form-check-label" for=""> Ya </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Tidak" v-model="confined_space.apakah_gas_testing_dilakukan_oleh_orang_kompeten" />
                                                                <label class="form-check-label" for=""> Tidak </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="NA" v-model="confined_space.apakah_gas_testing_dilakukan_oleh_orang_kompeten" />
                                                                <label class="form-check-label" for=""> NA </label>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table>
                                                <tr>
                                                    <th>Initial Test</th>
                                                    <td>
                                                        <table class="table table-sm table-bordered table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Test</th>
                                                                    <th>Pembacaan (Atas, Tengah, Bawah)</th>
                                                                    <th>Hasil</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(val_initial, key_initial) in confined_space.initial_test" :key="key_initial">
                                                                    <td>{{ val_initial.label }}</td>
                                                                    <td>
                                                                        <input type="text" v-model="val_initial.pembacaan" class="form-control form-control-sm" placeholder="Pembacaan (Atas, tengah, bawah)" />
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" v-model="val_initial.hasil" class="form-control form-control-sm" placeholder="Hasil" />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Final Test</th>
                                                    <td>
                                                        <table class="table table-sm table-bordered table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Test</th>
                                                                    <th>Pembacaan (Atas, Tengah, Bawah)</th>
                                                                    <th>Hasil</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(val_final, key_final) in confined_space.final_test" :key="key_final">
                                                                    <td>{{ val_final.label }}</td>
                                                                    <td>
                                                                        <input type="text" v-model="val_final.pembacaan" class="form-control form-control-sm" placeholder="Pembacaan (Atas, tengah, bawah)" />
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" v-model="val_final.hasil" class="form-control form-control-sm" placeholder="Hasil" />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Persiapan</label>
                                            <table class="table table-sm table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Item untuk dicek</th>
                                                        <th>Ya / Tidak / NA</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(data_cp, key_cp) in confined_space.checklist_persiapan" :key="key_cp">
                                                        <td>
                                                            {{ key_cp + 1 }}
                                                        </td>
                                                        <td>
                                                            <div v-html="data_cp.label"></div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Ya" v-model="data_cp.checklist" />
                                                                <label class="form-check-label" for=""> Ya </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Tidak" v-model="data_cp.checklist" />
                                                                <label class="form-check-label" for=""> Tidak </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="NA" v-model="data_cp.checklist" />
                                                                <label class="form-check-label" for=""> NA </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Lampiran <span class="text-danger">*</span> </label>
                                            <div class="row">
                                                <div v-for="(row_data, key_data) in confined_space.lampiran" :key="key_data" class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" />
                                                        <label class="form-check-label" for="">
                                                            {{ row_data.label }}
                                                        </label>
                                                        <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                            <input type="text" v-model="row_data.nama_lain" class="form-control form-control-sm" placeholder="Sebutkan..." />
                                                        </div>
                                                        <div v-if="row_data.checklist">
                                                            <table class="table table-sm table-border">
                                                                <thead>
                                                                    <tr>
                                                                        <th>No</th>
                                                                        <th>Nama File</th>
                                                                        <th>File</th>
                                                                        <th>
                                                                            <div class="btn btn-sm btn-success bg-success" @click="addLampiranBahayaTinggi('confined_space', key_data)">
                                                                                <i class="bx bx-plus"></i>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(row_dt, key_dt) in row_data.file" :key="key_dt">
                                                                        <td>{{ key_dt + 1 }}</td>
                                                                        <td>
                                                                            <input type="text" class="form-control mb-0 form-control-sm" v-model="row_dt.nama_file" />
                                                                        </td>
                                                                        <td>
                                                                            <input type="file" class="form-control mb-0 form-control-sm" :id="'files-doc-lampiran-confined_space-' + key_data + '-' + key_dt" v-on:change="inputFileDocumentconfined_space(key_data, key_dt)" accept=".pdf, .word, .excel, .png, .jpg" />
                                                                            <div class="respond-input-file float-left" :id="'duloading-lampiran-confined_space-' + key_data + '-' + key_dt">
                                                                                <div v-if="row_data.file[0].url && aksi == 'edit'">
                                                                                    <span class="badge bg-success p-1" @click="seeDocument(row_data.file[0].url)"><i class="fa fa-eye"></i> See Document </span>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <small class="float-right">*) Max File 2 MB </small>
                                                                        </td>
                                                                        <td>
                                                                            <div class="btn btn-danger btn-sm" v-on:click="removeLampiranBahayaTinggi('confined_space', key_data, key_dt)"><i class="bx bx-minus"></i></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">APD yang diperlukan</label>
                                            <div class="row">
                                                <div v-for="(row_data, key_data) in confined_space.apd_yang_diperlukan" :key="key_data" class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" />
                                                        <label class="form-check-label" for="">
                                                            {{ row_data.label }}
                                                        </label>
                                                        <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                            <input type="text" v-model="row_data.nama_lain" class="form-control form-control-sm" placeholder="Sebutkan..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <h4>Acceptance</h4>
                                        </div>
                                        <div class="col-md-12">
                                            <label for="" class="d-block">Job Site Inspection / Implementation of Safety Measures</label>
                                            <table class="table table-sm table-bordered table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th>Apakah pekerjaan ini harus dihadiri oleh pengawas?</th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="At Job Start" v-model="confined_space.apakah_harus_dihadiri_pengawas" />
                                                                <label class="form-check-label" for=""> At Job Start </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Continuously" v-model="confined_space.apakah_harus_dihadiri_pengawas" />
                                                                <label class="form-check-label" for=""> Continuously </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="No" v-model="confined_space.apakah_harus_dihadiri_pengawas" />
                                                                <label class="form-check-label" for=""> No </label>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-card>
                            <b-card border-variant="dark" header="Energized Electrical Work Permit" v-if="bahaya_tinggi[4].checklist">
                                <b-card-body>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label class="d-block">Detail Lokasi Pekerjaan <span class="text-danger">*</span> </label>
                                            <input type="text" class="form-control" v-model="electrical.detail_lokasi" />
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Tools / Equipment khusus untuk digunakan <span class="text-danger">*</span> </label>
                                            <input type="text" class="form-control" v-model="electrical.tools" />
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Vendor representative <span class="text-danger">*</span> </label>
                                            <textarea v-model="electrical.vendor_representative" class="form-control" placeholder="Vendor Representative"></textarea>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Tanggal</label>
                                            <input placeholder="Tanggal" type="date" v-model="electrical.tanggal" :min="today" class="form-control" />
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="d-block">Durasi Kerja</label>
                                                        <input placeholder="Durasi Kerja" type="time" v-model="electrical.work_duration" class="form-control" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="d-block">Hingga</label>
                                                        <input placeholder="Hingga" type="time" v-model="electrical.work_duration_sampai" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <h4>Measures To Be Taken</h4>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Persiapan</label>
                                            <table class="table table-sm table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Item untuk dicek</th>
                                                        <th>Ya / Tidak / NA</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(data_cp, key_cp) in electrical.checklist_persiapan" :key="key_cp">
                                                        <td>
                                                            {{ key_cp + 1 }}
                                                        </td>
                                                        <td>
                                                            <div v-html="data_cp.label"></div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Ya" v-model="data_cp.checklist" />
                                                                <label class="form-check-label" for=""> Ya </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Tidak" v-model="data_cp.checklist" />
                                                                <label class="form-check-label" for=""> Tidak </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="NA" v-model="data_cp.checklist" />
                                                                <label class="form-check-label" for=""> NA </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Lampiran <span class="text-danger">*</span> </label>
                                            <div class="row">
                                                <div v-for="(row_data, key_data) in electrical.lampiran" :key="key_data" class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" />
                                                        <label class="form-check-label" for="">
                                                            {{ row_data.label }}
                                                        </label>
                                                        <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                            <input type="text" v-model="row_data.nama_lain" class="form-control form-control-sm" placeholder="Sebutkan..." />
                                                        </div>
                                                        <div v-if="row_data.checklist">
                                                            <table class="table table-sm table-border">
                                                                <thead>
                                                                    <tr>
                                                                        <th>No</th>
                                                                        <th>Nama File</th>
                                                                        <th>File</th>
                                                                        <th>
                                                                            <div class="btn btn-sm btn-success bg-success" @click="addLampiranBahayaTinggi('electrical', key_data)">
                                                                                <i class="bx bx-plus"></i>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(row_dt, key_dt) in row_data.file" :key="key_dt">
                                                                        <td>{{ key_dt + 1 }}</td>
                                                                        <td>
                                                                            <input type="text" class="form-control mb-0 form-control-sm" v-model="row_dt.nama_file" />
                                                                        </td>
                                                                        <td>
                                                                            <input type="file" class="form-control mb-0 form-control-sm" :id="'files-doc-lampiran-electrical-' + key_data + '-' + key_dt" v-on:change="inputFileDocumentElectrical(key_data, key_dt)" accept=".pdf, .word, .excel, .png, .jpg" />
                                                                            <div class="respond-input-file float-left" :id="'duloading-lampiran-electrical-' + key_data + '-' + key_dt">
                                                                                <div v-if="row_data.file[0].url && aksi == 'edit'">
                                                                                    <span class="badge bg-success p-1" @click="seeDocument(row_data.file[0].url)"><i class="fa fa-eye"></i> See Document </span>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <small class="float-right">*) Max File 2 MB </small>
                                                                        </td>
                                                                        <td>
                                                                            <div class="btn btn-danger btn-sm" v-on:click="removeLampiranBahayaTinggi('electrical', key_data, key_dt)"><i class="bx bx-minus"></i></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">APD yang diperlukan</label>
                                            <div class="row">
                                                <div v-for="(row_data, key_data) in electrical.apd_yang_diperlukan" :key="key_data" class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" />
                                                        <label class="form-check-label" for="">
                                                            {{ row_data.label }}
                                                        </label>
                                                        <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                            <input type="text" v-model="row_data.nama_lain" class="form-control form-control-sm" placeholder="Sebutkan..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <h4>Acceptance</h4>
                                        </div>
                                        <div class="col-md-12">
                                            <label for="" class="d-block">Job Site Inspection / Implementation of Safety Measures</label>
                                            <table class="table table-sm table-bordered table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th>Apakah pekerjaan ini harus dihadiri oleh pengawas?</th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="At Job Start" v-model="electrical.apakah_harus_dihadiri_pengawas" />
                                                                <label class="form-check-label" for=""> At Job Start </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Continuously" v-model="electrical.apakah_harus_dihadiri_pengawas" />
                                                                <label class="form-check-label" for=""> Continuously </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="No" v-model="electrical.apakah_harus_dihadiri_pengawas" />
                                                                <label class="form-check-label" for=""> No </label>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-card>
                            <b-card border-variant="dark" header="Heavy lifting" v-if="bahaya_tinggi[5].checklist">
                                <b-card-body>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label class="d-block">Detail Lokasi Pekerjaan <span class="text-danger">*</span> </label>
                                            <input type="text" class="form-control" v-model="heavy_lifting.detail_lokasi" />
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Peralatan angkut yang digunakan <span class="text-danger">*</span> </label>
                                            <input type="text" class="form-control" v-model="heavy_lifting.peralatan_angkat" />
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Vendor representative <span class="text-danger">*</span> </label>
                                            <textarea v-model="heavy_lifting.vendor_representative" class="form-control" placeholder="Vendor Representative"></textarea>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Tanggal <span class="text-danger">*</span> </label>
                                            <input type="date" class="form-control" v-model="heavy_lifting.tanggal" :min="today" />
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="d-block">Durasi Kerja</label>
                                                        <input placeholder="Durasi Kerja" type="time" v-model="heavy_lifting.work_duration" class="form-control" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="d-block">Hingga</label>
                                                        <input placeholder="Hingga" type="time" v-model="heavy_lifting.work_duration_sampai" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Metode Pengangkatan <span class="text-danger">*</span> </label>
                                            <small>(Berikan penjelasan cara melakukan pengangkatan)</small>
                                            <textarea placeholder="Metode Pengangkatan" v-model="heavy_lifting.metode_pengangkatan" class="form-control"></textarea>
                                        </div>
                                        <div class="col-md-12">
                                            <h4>Measures To Be Taken</h4>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Persiapan</label>
                                            <table class="table table-sm table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Item untuk dicek</th>
                                                        <th>Ya / Tidak / NA</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(data_cp, key_cp) in heavy_lifting.checklist_persiapan" :key="key_cp">
                                                        <td>
                                                            {{ key_cp + 1 }}
                                                        </td>
                                                        <td>
                                                            <div v-html="data_cp.label"></div>
                                                        </td>
                                                        <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Ya" v-model="data_cp.checklist" />
                                                                <label class="form-check-label" for=""> Ya </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Tidak" v-model="data_cp.checklist" />
                                                                <label class="form-check-label" for=""> Tidak </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="NA" v-model="data_cp.checklist" />
                                                                <label class="form-check-label" for=""> NA </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">Lampiran <span class="text-danger">*</span> </label>
                                            <div class="row">
                                                <div v-for="(row_data, key_data) in heavy_lifting.lampiran" :key="key_data" class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" />
                                                        <label class="form-check-label" for="">
                                                            {{ row_data.label }}
                                                        </label>
                                                        <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                            <input type="text" v-model="row_data.nama_lain" class="form-control form-control-sm" placeholder="Sebutkan..." />
                                                        </div>
                                                        <div v-if="row_data.checklist">
                                                            <table class="table table-sm table-border">
                                                                <thead>
                                                                    <tr>
                                                                        <th>No</th>
                                                                        <th>Nama File</th>
                                                                        <th>File</th>
                                                                        <th>
                                                                            <div class="btn btn-sm btn-success bg-success" @click="addLampiranBahayaTinggi('heavy_lifting', key_data)">
                                                                                <i class="bx bx-plus"></i>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(row_dt, key_dt) in row_data.file" :key="key_dt">
                                                                        <td>{{ key_dt + 1 }}</td>
                                                                        <td>
                                                                            <input type="text" class="form-control mb-0 form-control-sm" v-model="row_dt.nama_file" />
                                                                        </td>
                                                                        <td>
                                                                            <input type="file" class="form-control mb-0 form-control-sm" :id="'files-doc-lampiran-heavy_lifting-' + key_data + '-' + key_dt" v-on:change="inputFileDocumentheavy_lifting(key_data, key_dt)" accept=".pdf, .word, .excel, .png, .jpg" />
                                                                            <div class="respond-input-file float-left" :id="'duloading-lampiran-heavy_lifting-' + key_data + '-' + key_dt">
                                                                                <div v-if="row_data.file[0].url && aksi == 'edit'">
                                                                                    <span class="badge bg-success p-1" @click="seeDocument(row_data.file[0].url)"><i class="fa fa-eye"></i> See Document </span>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <small class="float-right">*) Max File 2 MB </small>
                                                                        </td>
                                                                        <td>
                                                                            <div class="btn btn-danger btn-sm" v-on:click="removeLampiranBahayaTinggi('heavy_lifting', key_data, key_dt)"><i class="bx bx-minus"></i></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block">APD yang diperlukan</label>
                                            <div class="row">
                                                <div v-for="(row_data, key_data) in heavy_lifting.apd_yang_diperlukan" :key="key_data" class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" />
                                                        <label class="form-check-label" for="">
                                                            {{ row_data.label }}
                                                        </label>
                                                        <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                            <input type="text" v-model="row_data.nama_lain" class="form-control form-control-sm" placeholder="Sebutkan..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <h4>Acceptance</h4>
                                        </div>
                                        <div class="col-md-12">
                                            <label for="" class="d-block">Job Site Inspection / Implementation of Safety Measures</label>
                                            <table class="table table-sm table-bordered table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th>Apakah pekerjaan ini harus dihadiri oleh pengawas?</th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="At Job Start" v-model="heavy_lifting.apakah_harus_dihadiri_pengawas" />
                                                                <label class="form-check-label" for=""> At Job Start </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="Continuously" v-model="heavy_lifting.apakah_harus_dihadiri_pengawas" />
                                                                <label class="form-check-label" for=""> Continuously </label>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" value="No" v-model="heavy_lifting.apakah_harus_dihadiri_pengawas" />
                                                                <label class="form-check-label" for=""> No </label>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </div>

                        <b-button type="button" variant="warning" class="btn btn-block" style="width: 100%" v-on:click="submitToData('draft')"><i class="fa fa-bookmark"></i> Save Draft</b-button>
                        <b-button type="button" variant="success" class="btn btn-block" style="width: 100%; margin-top: 10px" v-on:click="submitToData('submited')"><i class="fa fa-save"></i> Submit</b-button>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
        <b-modal v-model="modal_submit" hide-footer centered header-class="border-0" title="Submit Work Permit" size="lg">
            <div class="mb-4">
                <div class="row">
                    <div class="col-md-12 text-end">
                        <iframe v-bind:src="pdf_path" style="width: 100%; height: 350px"></iframe>
                    </div>
                </div>
            </div>
            <div class="mb-4" v-if="status_modal_submit == 'pilih_administrator'">
                <div class="row">
                    <div class="col-md-12 text-end">
                        <v-select :options="optionAdministrator" label="name" v-model="administratorSelected" placeholder="Choose Administrator"></v-select>
                    </div>
                </div>
            </div>
            <div class="text-end mt-3 button-items">
                <b-button v-if="status_modal_submit == 'esign'" variant="success" v-on:click="esignPDF()"> <i class="bx bx-save"></i> E-sign</b-button>
                <b-button v-else-if="status_modal_submit == 'pilih_administrator'" variant="success" v-on:click="SubmitAdministrator()"> <i class="bx bx-save"></i> Submit </b-button>
            </div>
        </b-modal>
        <b-modal v-model="overtime_modal" hide-footer centered header-class="border-0" title="Overtime Permit" size="lg">
            <div class="mb-4">
                <div class="row">
                    <div class="col-md-12">
                        <label class="d-block">Detail Lokasi Pekerjaan <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" v-model="overtime.detail_lokasi" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="d-block">Durasi Kerja</label>
                            <input placeholder="Durasi Kerja" type="time" v-model="overtime.durasi_kerja_dari" class="form-control" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="d-block">Hingga</label>
                            <input placeholder="Hingga" type="time" v-model="overtime.durasi_kerja_sampai" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-sm table-border">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Company Name</th>
                                    <th>
                                        <div class="btn btn-sm btn-success bg-success" @click="addOvertimePerformingTask()">
                                            <i class="bx bx-plus"></i>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row_dt, key_dt) in overtime.performing_task" :key="key_dt">
                                    <td>
                                        <input type="text" class="form-control mb-0 form-control-sm" v-model="row_dt.nama" :disabled="row_dt.is_disabled" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control mb-0 form-control-sm" v-model="row_dt.perusahaan" :disabled="row_dt.is_disabled" />
                                    </td>
                                    <td>
                                        <div class="btn btn-danger btn-sm" v-on:click="removeOvertimePerformingTask(key_dt)"><i class="bx bx-minus"></i></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label class="d-block">Description Of Task <span class="text-danger">*</span></label>
                        <textarea placeholder="Description Of Task" type="text" v-model="overtime.description_task" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="text-end mt-3 button-items">
                <b-button variant="light" v-on:click="overtime_modal = false"> <i class="bx bx-x"></i> Close </b-button>
                <b-button variant="primary" v-on:click="overtime_modal = false"> <i class="bx bx-save"></i> Save </b-button>
            </div>
        </b-modal>
    </Layout>
</template>

<style scoped>
>>> {
    --vs-dropdown-max-height: 250px !important;
}
</style>
<style>
.page-content {
    min-height: 100vh !important;
}
</style>
